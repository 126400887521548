import React, {useState} from 'react';
import {Button, Form, Input} from 'antd';
import {lang} from "../../../config/config";
import {firebaseAuthAPI} from "../../../features/firebase/auth/firebaseAuthAPI";
import {useSelector} from "react-redux";
import {userData} from "../../../features/firebase/firestore/firestoreCurrentUserData";

const EmailValidationForm = () => {

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(userData)

  const onFinishFailed = () => {
  }

  const onFinish = () => {
    setIsLoading(true)
    firebaseAuthAPI.updateAuthEmail(form.getFieldValue('email')).then(() => {
      setIsLoading(false)
    })
  }

  return (
     <Form
        name={'form_profile_email'}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
     >
       <Form.Item
          style={{width: '100%'}}
          name="email"
          hasFeedback
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Vänligen ange en e-post',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (value !== user.email) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Angiven e-post är samma som nuvarande'));
              },
            }),
          ]}
       >
         <Input placeholder={lang.EMAIL}/>
       </Form.Item>

       <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
         <Button type={"primary"} loading={isLoading} htmlType={'submit'}>{lang.EMAIL_CHANGE}</Button>
       </div>

     </Form>
  )
};

export default EmailValidationForm;
