import {
  AimOutlined,
  CalendarOutlined,
  ClockCircleOutlined,
  ContactsOutlined,
  CreditCardOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

export const IC_CALENDER = <CalendarOutlined className={'ClassItem-icon-calender'}/>
export const IC_CLOCK = <ClockCircleOutlined className={'ClassItem-icon-clock'}/>
export const IC_CONTACTS = <UserOutlined className={'ClassItem-icon-contact'}/>
export const IC_SLOTS = <TeamOutlined className={'ClassItem-icon-slots'}/>
export const IC_CARD = <CreditCardOutlined className={'ClassItem-icon-card'}/>
export const IC_LOCATION = <AimOutlined className={'ClassItem-icon-location'}/>

export const IC_CALENDER_COMPACT = <CalendarOutlined className={'ClassItem-icon-calender-compact'}/>
export const IC_CLOCK_COMPACT = <ClockCircleOutlined className={'ClassItem-icon-clock-compact'}/>
export const IC_CONTACTS_COMPACT = <ContactsOutlined className={'ClassItem-icon-contact-compact'}/>
export const IC_SLOTS_COMPACT = <TeamOutlined className={'ClassItem-icon-slots-compact'}/>
export const IC_CARD_COMPACT = <CreditCardOutlined className={'ClassItem-icon-card-compact'}/>

export const IC_USER_COMPACT = <UserOutlined className={'Icon-user-compact'}/>

// export const IC_BOOKED_CLASS = props => { return (<CheckCircleTwoTone className={'ClassItem-icon-booked-class'} {...props} />)}
// export const IC_RESERVED_CLASS = props => { return (<ScheduleTwoTone className={'ClassItem-icon-reserved-class'} {...props} />)}



