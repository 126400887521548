import React, {useState} from 'react'
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Popconfirm,
  Select,
  TimePicker
} from "antd";
import {CLASSES_SEQUENCES_ARR, CLASSES_SLOTS_ARR, EVENT_SLOTS_ARR, lang} from "../../config/config";
import {TextFieldCharCounter} from "../SharedComponents/Input/TextFieldCharCounter";
import {
  checkAdminAccess,
  checkInstructorAccess,
  displayHttpResponseMessage,
  epochToHHMMStr,
  findCategory,
  firstLetterToUppercase,
  getAvailableSlots,
  getDetailedDateTime,
  getTimeRangeLongTitleCased,
  hasClassEnded,
  hasClassStarted,
  isAttendanceAvailable,
  isEpochMillisInFuture,
  minutesBetweenDates,
  titleCase
} from "../../util";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {userData} from "../../features/firebase/firestore/firestoreCurrentUserData";
import {
  CheckCircleFilled,
  DeleteFilled,
  DeleteOutlined,
  DownOutlined,
  EditFilled,
  IdcardOutlined,
  MailOutlined,
  OrderedListOutlined,
  PhoneOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import {UsePopConfirm} from "../SharedComponents/Input";
import {IC_CALENDER, IC_CALENDER_COMPACT, IC_CLOCK, IC_CONTACTS, IC_LOCATION, IC_SLOTS} from "../Icons/Icons";
import './Classes.css'
import {
  addBooking,
  addReserveBooking,
  removeBookingAdmin,
  removeBookingReservedAdmin,
  updateClass
} from "../../features/firebase/firestore/firestoreClassesSlice";
import {CardForm} from "../SharedComponents/View";

const {Option} = Select

export const InputClassName = ({initialValue}) => {

  const [charCount, setCharCount] = useState(initialValue ? initialValue.length : 0)
  const maxCount = 30

  const getCharCount = (e) => {
    setCharCount(e.target.value.length)
  }

  return (
     <React.Fragment>

       <Form.Item
          name={'name'}
          label={lang.CLASS_NAME}
          style={{width: '100%'}}
          initialValue={initialValue ? initialValue : ''}
          rules={[{
            required: true,
            message: 'Ett namn saknas'
          }]}>

         <Input
            allowClear
            type={"text"}
            placeholder={lang.SUPPLY_A_CLASS_NAME}
            style={{width: '100%'}}
            maxLength={maxCount}
            onChange={(e) => getCharCount(e)}
         />
       </Form.Item>

       <TextFieldCharCounter charCount={charCount} max={maxCount}/>

     </React.Fragment>
  )
}

export const InputDescription = ({initialValue, maxLength}) => {

  const [charCount, setCharCount] = useState(initialValue ? initialValue.length : 0)
  const maxCount = maxLength ? maxLength : 115

  const getCharCount = (e) => {
    setCharCount(e.target.value.length)
  }

  return (
     <React.Fragment>
       <Form.Item
          name={'description'}
          label={lang.DESCRIPTION}
          style={{width: '100%'}}
          initialValue={initialValue ? initialValue : ''}
          rules={[{
            required: true,
            message: 'En beskrivning saknas'
          }]}>

         <Input
            allowClear
            type={"text"}
            placeholder={lang.SUPPLY_A_DESCRIPTION}
            style={{width: '100%'}}
            autoCapitalize={['sentences']}
            maxLength={maxCount}
            onChange={(e) => getCharCount(e)}
         />
       </Form.Item>

       <TextFieldCharCounter charCount={charCount} max={maxCount}/>

     </React.Fragment>
  )
}

export const InputLengthMinutes = ({array, initialValue}) => {

  return (

     <Form.Item
        name={'lengthMinutes'}
        label={lang.CLASS_LENGTH}
        initialValue={initialValue ? initialValue : array ? array[0] : null}
        style={{width: '100%'}}
        rules={[{
          required: true,
          message: 'Längd saknas'
        }]}
     >
       <Select
       >
         {
           array.map((num) => (
              <Select.Option key={`length_${num}`} value={num}>{`${num} min`}</Select.Option>
           ))
         }
       </Select>
     </Form.Item>
  )
}

export const InputMaxSlots = ({initialValue}) => {

  return (

     <Form.Item
        name={'slots'}
        label={lang.AVAILABLE_SLOTS}
        initialValue={initialValue ? initialValue : CLASSES_SLOTS_ARR ? CLASSES_SLOTS_ARR[CLASSES_SLOTS_ARR.length - 1] : null}
        style={{width: '100%'}}
     >
       <Select>
         {
           CLASSES_SLOTS_ARR.map((num) => (
              <Select.Option key={`slots_${num}`} value={num}>{num}</Select.Option>
           ))
         }
       </Select>
     </Form.Item>
  )
}

export const InputEventMaxSlots = ({initialValue}) => {

  return (

     <Form.Item
        name={'slots'}
        label={lang.AVAILABLE_SLOTS}
        initialValue={initialValue ? initialValue : EVENT_SLOTS_ARR ? EVENT_SLOTS_ARR[EVENT_SLOTS_ARR.length - 1] : null}
        style={{width: '100%'}}
     >
       <Select>
         {
           EVENT_SLOTS_ARR.map((num) => (
              <Select.Option key={`slots_${num}`} value={num}>{num}</Select.Option>
           ))
         }
       </Select>
     </Form.Item>
  )
}

export const InputCost = ({initialValue}) => {

  return (
     <Form.Item
        name={'cost'}
        label={lang.EVENT_COST}
        initialValue={initialValue ? initialValue : 100}
        style={{width: '100%'}}
     >
       <Input
          type={'number'}
          style={{width: '100%'}}
          addonAfter="kr"
       />
     </Form.Item>
  )
}

export const InputSequence = () => {
  return (
     <Form.Item
        name={'sequences'}
        label={'Antal'}
        tooltip={{title: 'Detta skapar ifyllt pass + antal valda nedan'}}
        initialValue={CLASSES_SEQUENCES_ARR[0]}
        style={{width: '100%', marginTop: 5, marginBottom: 5}}
     >
       <Select>
         {
           CLASSES_SEQUENCES_ARR.map((i) => (
              <Select.Option key={`sequences_${i}`} value={i}>{i}</Select.Option>
           ))
         }
       </Select>
     </Form.Item>
  )
}

export const InputStartDate = ({initialValue}) => {

  function disabledDate(current) {
    const date = new Date()
    date.setDate(date.getDate() - 1)
    return current < date.getTime()
  }

  function initialDate() {
    const date = new Date()
    date.setDate(initialValue)
    return date.getTime()
  }

  return (
     <Form.Item
        name={'startDate'}
        label={lang.SUPPLY_A_DATE}
        style={{width: '100%'}}
        initialValue={initialValue ? initialDate() : null}
        rules={[{
          required: true,
          message: 'Avser datum krävs!'
        }]}
     >

       <DatePicker
          showNow={false}
          style={{width: '100%'}}
          disabledDate={disabledDate}
          placeholder={lang.SUPPLY_A_DATE}
       />

     </Form.Item>
  )
}

export const InputClassTime = () => {

  const minuteStep = 5

  function dHours() {
    return [0, 1, 2, 3, 4, 5, 6, 23, 24]
  }

  return (
     <Form.Item

        name={'endTime'}
        label={lang.SUPPLY_A_TIME}
        style={{width: '100%'}}
        // initialValue={initialValue ? epochToHHMMStr(initialValue) : array[0]}
        rules={[{
          required: true,
          message: 'Ett klockslag krävs!'
        }]}
     >

       <TimePicker
          format={'HH:mm'}
          style={{
            width: '100%',
          }}
          showNow={false}
          hideDisabledOptions={true}
          minuteStep={minuteStep}
          showSecond={false}
          disabledHours={dHours}
          placeholder={lang.SUPPLY_A_TIME}
       />

     </Form.Item>
  )
}


export const InputCategory = ({array, initialValue}) => {

  return (

     <Form.Item
        name={'category'}
        label={lang.CATEGORY}
        initialValue={initialValue ? initialValue.value : array[0].value}
        style={{width: '100%'}}
     >
       <Select>
         {
           array.map((item) => (
              <Select.Option key={item.value} value={item.value}>{item.displayText}</Select.Option>
           ))
         }
       </Select>

     </Form.Item>

  )
}

export const InputInstructor = ({array, initialValue}) => {

  return (

     <Form.Item
        name={'instructor'}
        label={lang.INSTRUCTOR}
        initialValue={initialValue ? initialValue : array.length ? `${array[0].displayName}` : null}
        style={{width: '100%'}}
        rules={[{
          required: true,
          message: 'En instruktör krävs'
        }]}
     >
       <Select
          showSearch
       >
         {
           array.map((item) => (
              <Option key={item.uid} value={item.displayName}>{`${item.displayName}`}</Option>
           ))
         }
       </Select>

     </Form.Item>

  )

}

export const CategoryTitle = ({category, text}) => {

  const catObj = findCategory(category.value)

  return (
     <div className={'Category-tag'} style={{backgroundColor: catObj.color}}>
       {`${text}`}
     </div>
  )
}
export const ClassOptionDisabledText = ({text}) => {

  return (
     <div style={{
       width: '100%',
       userSelect: 'none',
       borderStyle: 'dashed',
       borderColor: 'rgba(0,0,0, 0.05)',
       padding: '0.15rem',
       color: 'rgba(0,0,0, 0.4)',
       textAlign: 'center'
     }}
          onClick={() => {
          }}>{text}
     </div>
  )
}

export const ConfigRowAdmin = ({pathEdit, onClickDelete, item}) => {

  const history = useHistory()
  const user = useSelector(userData)

  const onEditClick = () => {
    history.push(`${pathEdit}/${item.documentId}`)
  }

  const navigateToAttendance = () => {
    history.push({ pathname: `${pathEdit}/attendance/${item.documentId}`, state: { edit: true, backPath: `${pathEdit}` }})
  }

  const handleConfirm = () => {
    onClickDelete()
  }

  const displayAttendanceBtn = !isAttendanceAvailable(item.bookedUsers) && item.isActive && !hasClassEnded(item.timeRange.classEnd) ?
     <Button type={'primary'}
             icon={<OrderedListOutlined/>}
             shape={'circle'}
             style={{backgroundColor: '#36C190', border: 0, width: 42, height: 42, marginLeft: 15}}
             onClick={() => navigateToAttendance()}
     /> : null

  return checkAdminAccess(user) ? (
     <div style={{display: 'flex', flexDirection: 'row-reverse'}}>

       <Button type={'primary'}
               icon={<EditFilled/>}
               shape={'circle'}
               style={{width: 42, height: 42, marginLeft: 15}}
               onClick={() => onEditClick()}
       />

       {displayAttendanceBtn}

       <Popconfirm
          okType={"danger"}
          title={lang.ASK_DELETE}
          onConfirm={() => handleConfirm()}
          okText={lang.YES}
          cancelText={lang.ABORT}
       >
         <Button key={'popconfirm-button'}
                 type={'primary'}
                 style={{width:  42, height: 42}}
                 shape={'circle'}
                 danger
                 icon={<DeleteOutlined/>}
                 onClick={() => {
                 }}/>

       </Popconfirm>
     </div>
  ) : null
}

export const ConfigRowInstructor = ({item}) => {

  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(userData)

  const navigateToFillAttendance = () => {
    history.push({ pathname: `/attendance/${item.documentId}`, state: { edit: true, backPath: `/my-classes` }})
  }

  const navigateToViewAttendance = () => {
    history.push({ pathname: `/attendance/${item.documentId}`, state: { edit: false, backPath: `/my-classes` }})
  }

  const onEndClass = () => {
    dispatch(updateClass({
      documentId: item.documentId,
      data: {
        isActive: false
      }
    })).then((response) => {
      message.info(response.payload.message)
    })
  }

  const displayAttendanceNonEditableBtn = !isAttendanceAvailable(item.bookedUsers) && item.isActive ?
     <Button type={'primary'} icon={<TeamOutlined/>}
             shape={'circle'}
             style={{width: 42, height: 42, marginLeft: 15}}
             onClick={() => navigateToViewAttendance()}/> : null

  const displayAttendanceBtn = !isAttendanceAvailable(item.bookedUsers) && item.isActive && !hasClassEnded(item.timeRange.classEnd) ?
     <Button type={'primary'}
             icon={<OrderedListOutlined/>} shape={'circle'}
             style={{backgroundColor: '#36C190', border: 0, width: 42, height: 42, marginLeft: 15}}
             onClick={() => navigateToFillAttendance()}/> : null

  const displayNotStartedYet = !hasClassStarted(item.timeRange.classStart) && item.isActive ?
     <ClassOptionDisabledText text={'Pass ej avslutat än'}/> : null

  const displayNoBookingsHasEndedBtn = !hasClassEnded(item.timeRange.classStart) && isAttendanceAvailable(item.bookedUsers) ?
     <UsePopConfirm
        key={'popconfirm'}
        icon={<CheckCircleFilled style={{color: 'hotpink'}}/>}
        onClick={() => onEndClass()}
        titleText={lang.ASK_DELETE}
        components={[
          <Button key={'popconfirm-button'}
                  type={'primary'}
                  style={{width:  42, height: 42}}
                  shape={'circle'}
                  danger
                  icon={<DeleteOutlined/>}
                  onClick={() => {
                  }}/>,
        ]}
     /> : null

  return checkInstructorAccess(user) ? (
     <div style={{display: 'flex', flexDirection: 'row-reverse'}}>

       {displayNotStartedYet}
       {displayNoBookingsHasEndedBtn}
       {displayAttendanceBtn}
       {displayAttendanceNonEditableBtn}

     </div>
  ) : null
}

export const SequenceClass = ({currentState, onStateChange}) => {

  return (
     <React.Fragment>
       <Checkbox checked={currentState} onClick={onStateChange()}>{lang.REOCCURRING}</Checkbox>
       {currentState ? <InputSequence/> : null}
     </React.Fragment>
  )

}

export const ClassItem = ({item}) => {

  const title = `${titleCase(item.name)}`
  const dated = `${getTimeRangeLongTitleCased(item.timeRange.classStart)}`
  const slotRange = `${getAvailableSlots(item.bookedUsers, item.slotRange.total)} av ${item.slotRange.total} lediga`
  const reservedRange = item?.reservedUsers?.length !== 0 ? `${item?.reservedUsers?.length} har reserverat` : null
  const classStartEnd = `${epochToHHMMStr(item.timeRange.classStart)} - ${epochToHHMMStr(item.timeRange.classEnd)}`
  const classLength = `${minutesBetweenDates(item.timeRange.classStart, item.timeRange.classEnd)} minuter`
  const instructor = `${item.instructor.displayName}`
  const description = `${firstLetterToUppercase(item.description)}`

  return item ? (
        <div style={{display: 'flex', flexDirection: 'column', borderColor: item.category.color}}>
          <div className={'ClassItem name'}>
            {`${title}`}
          </div>

          <div className={'ClassItem category-line'} style={{backgroundColor: item.category.color}}/>

          <div className={'ClassItem category-text'} style={{color: item.category.color}}>
            {`${item.category.displayText}`}
          </div>

          <div className={'ClassItem date'}>
            {IC_CALENDER}{dated}
          </div>

          <div className={'ClassItem time'}>
            <div>{IC_CLOCK}{classStartEnd}</div>
            <div>{classLength}</div>
          </div>

          <div className={'ClassItem slots'}>
            <div>{IC_SLOTS}{slotRange}</div>
            {reservedRange ? <div>{IC_SLOTS}{reservedRange}</div> : null}
          </div>

          <div className={'ClassItem instructor'}>
            {IC_CONTACTS}{instructor}
          </div>

          <div className={'ClassItem location'}>
            {IC_LOCATION}{item.location}
          </div>

          <div className={'ClassItem description'}>
            <blockquote>{description}</blockquote>
          </div>

        </div>
     ) :
     null
}

export const BookingClassActionsRow = ({item}) => {

  const dispatch = useDispatch()
  const user = useSelector(userData)
  const isUserBooked = item.bookedUsers?.find(x => (x).uid === user.uid)
  const isUserReserve = item.reservedUsers?.find(x => (x).uid === user.uid)
  const isBookingAvailable = getAvailableSlots(item.bookedUsers, item.slotRange.total) !== 0

  const onBookClick = () => {
    // console.log(item.documentId)
    dispatch(addBooking({
      documentId: item.documentId,
      data: {
        displayName: user.displayName,
        uid: user.uid,
        bookedAt: new Date().getTime(),
        email: user.email
      }
    })).then(() => {
      message.success('Pass har blivit bokat') //PROPER MESSAGE FROM RESPONSE
    })
  }

  const onBookReserveClick = () => {

    dispatch(addReserveBooking({
      documentRef: item,
      data: {
        displayName: user.displayName,
        uid: user.uid,
        bookedAt: new Date().getTime(),
        email: user.email
      }
    })).then(() => {
      message.success('Du har bokat en reservplats') //PROPER MESSAGE FROM RESPONSE
    })
  }


  const btnBookDisplay = !isUserBooked && !isUserReserve && isBookingAvailable ?
     <UsePopConfirm
        // key={`button-booking-${item.documentId}`}
        icon={<CheckCircleFilled style={{color: 'hotpink'}}/>}
        onClick={() => onBookClick()}
        titleText={lang.ASK_BOOK_CLASS_SPOT}
        components={[
          <Button key={`button-booking-${item.documentId}`} type={'text'}>{lang.BOOK}</Button>,
        ]}/>
     : null

  const btnReserveDisplay = !isUserBooked && !isUserReserve && !isBookingAvailable ?
     <UsePopConfirm
        icon={<CheckCircleFilled style={{color: 'hotpink'}}/>}
        onClick={() => onBookReserveClick()}
        titleText={lang.ASK_BOOK_RESERVE_SPOT}
        components={[
          <Button key={`button-reserved-${item.documentId}`} type={'text'}>{lang.BOOK_RESERVE_SLOT}</Button>,
        ]}/> : null

  const displayHasReserved = isUserReserve ?
     <div
        key={`has-reserved-label-${item.documentId}`}
        className={'Booking-info-label'}
        style={{background: 'rgba(187,146,103, 0.8)'}}>{lang.SLOT_RESERVE_BOOKED}</div> : null

  const displayHasBooked = isUserBooked ?
     <div
        key={`has-booked-label-${item.documentId}`}
        className={'Booking-info-label'}
        style={{background: 'rgba(93,182,141, 0.8)'}}>{lang.SLOT_BOOKED}</div> : null

  return !isEpochMillisInFuture(user.disabledUntil) ? (
        <div>
          <div>


            {/*<BookButton availability={availability} onBookClick={onBookClick}/>*/}
            {btnReserveDisplay}
            {btnBookDisplay}
            {displayHasBooked}
            {displayHasReserved}
          </div>
        </div>
     ) :
     <div className={'Booking-info-label-disabled'}>{lang.USER_BLOCKED}</div>

}

export const BookedUsersEdit = ({classItem}) => {

  const dispatch = useDispatch()

  const onRemoveBookingClick = (booking) => {

    dispatch(removeBookingAdmin({
      documentRef: classItem,
      data: {...booking}
    })).then((response) => {
      displayHttpResponseMessage(response.payload)
    })
  }

  return (
     <CardForm
        key={'card-form-bookings-edit'}
        settings={{
          title: lang.BOOKED_USERS,
          icon: <IdcardOutlined key={'booked-ic'}/>
        }}
        components={[
          <div className={'Booked-users-edit'}>
            {classItem?.bookedUsers?.length !== 0 ?
               classItem?.bookedUsers?.map((booking, index) => (
                  <div
                     key={`booking-edit-${booking.bookedAt} ${index}`}
                     className={'Booked-users-edit item'}>

                    <b>{index + 1}. {booking.displayName}</b>
                    <i>{booking.email}</i>

                    <p>{IC_CALENDER_COMPACT}{getDetailedDateTime(booking.bookedAt)}</p>

                    <div>
                      <UsePopConfirm
                         type={'danger'}
                         icon={<DeleteFilled style={{color: 'red'}}/>}
                         onClick={() => onRemoveBookingClick(booking)}
                         titleText={lang.ASK_REMOVE_BOOKING}
                         components={[
                           <Button danger type={'default'}>{lang.REMOVE}</Button>,
                         ]}/>
                    </div>


                    <Divider dashed style={{marginTop: 15, marginBottom: 5}}/>

                  </div>
               )) : <i>Ingen har bokat en plats än!</i>
            }
          </div>
        ]}
     />
  )
}

export const ReservedBookingEdit = ({classItem}) => {

  const dispatch = useDispatch()

  const onRemoveBookingClick = (booking) => {

    dispatch(removeBookingReservedAdmin({
      documentRef: classItem,
      data: {...booking}
    })).then((response) => {
      displayHttpResponseMessage(response)
    })
  }

  return (
     <CardForm
        key={'card-form-bookings-reserved-edit'}
        settings={{
          title: lang.RESERVED_USERS,
          icon: <IdcardOutlined key={'reserved-ic'}/>
        }}
        components={[
          <div className={'Booked-users-edit'}>
            {classItem?.reservedUsers.length !== 0 ?
               classItem?.reservedUsers?.map((booking, index) => (
                  <div key={`booking-edit-reserve-${booking.bookedAt} ${index}`} className={'Booked-users-edit item'}>

                    <b>{index + 1}. {booking.displayName}</b>
                    <i>{booking.email}</i>
                    <p>{IC_CALENDER_COMPACT}{getDetailedDateTime(booking.bookedAt)}</p>

                    <div>
                      <UsePopConfirm
                         type={'danger'}
                         icon={<DeleteFilled style={{color: 'red'}}/>}
                         onClick={() => onRemoveBookingClick(booking)}
                         titleText={lang.ASK_REMOVE_BOOKING_AS_RESERVE}
                         components={[
                           <Button danger type={'default'}>{lang.REMOVE}</Button>,
                         ]}/>
                    </div>

                    <Divider dashed style={{marginTop: 15, marginBottom: 5}}/>

                  </div>
               )) : <i>Ingen har reserverat plats än!</i>

            }

          </div>
        ]}
     />
  )
}

export const InputLocation = ({initialValue}) => {

  const [charCount, setCharCount] = useState(initialValue ? initialValue.length : 0)
  const maxCount = 30

  const getCharCount = (e) => {
    setCharCount(e.target.value.length)
  }

  return (
     <React.Fragment>

       <Form.Item
          name={'location'}
          label={lang.LOCATION}
          style={{width: '100%'}}
          initialValue={initialValue ? initialValue : ''}
          rules={[{
            required: true,
            message: 'En plats saknas'
          }]}
       >

         <Input
            allowClear
            type={"text"}
            placeholder={lang.SUPPLY_A_LOCATION}
            style={{width: '100%'}}
            maxLength={maxCount}
            onChange={(e) => getCharCount(e)}
         />

       </Form.Item>

       <TextFieldCharCounter charCount={charCount} max={maxCount}/>

     </React.Fragment>
  )
}


export const ContactButton = () => {

  const user = useSelector(userData)
  const userName = user ? user.displayName : ''


  function handleMenuClick(e) {
    switch (e.key) {
      case "1":
        window.location.href = `tel:${process.env.REACT_APP_CONTACT_PHONE}`;
        break;
      case "2":
        window.location.href = `mailto:${process.env.REACT_APP_CONTACT_EMAIL}?subject=Avser användare ${userName}&body=Ange beskrivning här \n`;
        break;
      default:
        e.preventDefault()
    }
    // message.info('Click on menu item.');
    console.log('click', e);
  }

  const menu = (
     <Menu onClick={handleMenuClick}>
       <Menu.Item key="1" icon={<PhoneOutlined/>}>
         Ring
       </Menu.Item>
       <Menu.Item key="2" icon={<MailOutlined/>}>
         E-post
       </Menu.Item>
     </Menu>
  );

  return (
     <Dropdown overlay={menu}>
       <Button type={'text'} style={{color: 'white'}}>
         Kontakta oss <DownOutlined/>
       </Button>
     </Dropdown>
  )
}
