import {CLASSES_CATEGORIES_ARR, TIMES_ARR} from "../config/config";
import {USER_ACCESS} from "../router/routes";
import {message} from "antd";
import {ClassNew, EventNew, Instructor, SlotRange} from "../features/firebase/firestore/data/classes";

const oneWeekMills = (1000 * 60 * 60 * 24 * 7)

export function findCategory(value) {
  const findCategoryObject = CLASSES_CATEGORIES_ARR.find(x => (x).value === value)
  return findCategoryObject ? findCategoryObject : null
}

export function findInstructor(array, value) {
  const findInstructorObject = array.find(x => (x) === value)
  return findInstructorObject ? findInstructorObject : null
}

export function findDisplayTime(array, value) {
  const findTimeObject = TIMES_ARR.find(x => (x).value === new Date(value).getTime())
  return findTimeObject ? findTimeObject.value : TIMES_ARR[TIMES_ARR.length - 1]
}

export function findObjKeyValue(array, key, value) {
  const item = array.find(x => (x)[key] === value)
  return item ? item : null
}

export function epochToHHMMStr(epochTime) {
  return `${new Date(epochTime).toLocaleTimeString('sv-SE').slice(0, 5)}`
}

export function isEpochMillisInFuture(epochTime) {
  return new Date(epochTime) > new Date()
}

export function setMillisOneWeekBackFromNow() {
  return new Date(new Date().getTime() - 604800000)
}

export function createGuestBooking(count, bookedUsers, email, name) {
  const dateNow = new Date()
  const arr = []
  for (let i = 0; i < count; i++) {
    arr.push({
      uid: `G-${dateNow.getTime()}-${bookedUsers?.length + i}`,
      bookedAt: dateNow.getTime(),
      displayName: `Gäst ${i + 1} ${name}`,
      email: email
    })
  }
  return arr
}


export function createClassUploadData(
   sessionStartDate,
   sessionStartTime,
   classLength,
   category,
   description,
   slots,
   userUid,
   instructor,
   name,
   location
) {
  const classStart = new Date(sessionStartDate)
  const classStartTime = new Date(sessionStartTime)
  classStart.setHours(classStartTime.getHours(), classStartTime.getMinutes(), +
     0, 0)
  const classEnd = new Date(classStart.getTime() + (classLength * 60 * 1000))

  const timeRange = {
    classStart: classStart.getTime(),
    classEnd: classEnd.getTime(),
    classLength: classLength,
  }

  return ClassNew(
     findCategory(category),
     description,
     SlotRange(slots),
     timeRange,
     userUid,
     Instructor(instructor.uid, instructor.displayName),
     name.toString().trimLeft(),
     location
  )
}

export function createEventData(
   eventStartDate,
   eventEndTime,
   eventLength,
   description,
   slots,
   userUid,
   name,
   cost,
   location
) {
  const classStart = new Date(eventStartDate)
  const classStartTime = new Date(eventEndTime)
  classStart.setHours(classStartTime.getHours(), classStartTime.getMinutes(), 0, 0)
  const classEnd = new Date(classStart.getTime() + (eventLength * 60 * 1000))

  const timeRange = {
    eventStart: classStart.getTime(),
    eventEnd: classEnd.getTime(),
    eventLength: eventLength,
  }

  return EventNew(
     description,
     SlotRange(slots),
     timeRange,
     userUid,
     name.toString().trimLeft(),
     cost,
     location
  )
}

export function minutesBetweenDates(date1, date2) {
  return Math.floor((new Date(date2) - new Date(date1)) / 60000);
}

export const getTimeRangeString = (start, end) => {
  return `${epochToHHMMStr(start)} - ${epochToHHMMStr(end)}`
}

export const getTimeRangeLongTitleCased = (epochTime) => {
  return `${titleCase(getLongDate(epochTime))}`
}

export const getDetailedDateTime = (epochTime) => {
  return `${getTimeRangeLongTitleCased(epochTime)} - ${epochToHHMMStr(epochTime)}`
}

export function createPrefixedUUID(prefix) {
  return `${prefix}-${new Date().getTime()}`
}

export function createTimeRanges(startHour, endHour, partHourBy) {
  const result = [];

  for (let hour = startHour; hour < endHour; hour++) {
    for (let minute = 0; minute < partHourBy; minute++) {

      const minutes = (minute * 60) / partHourBy;
      const date = new Date(0);
      date.setHours(hour, minutes, 0, 0);
      result.push(date.getTime());
    }
  }

  const endTime = new Date(0);
  endTime.setHours(endHour, 0, 0, 0);

  result.push(endTime.getDate());

  return result;
}

export function titleCase(str) {
  const arr = []
  for (let s of str.split(' ')) {
    arr.push(s.charAt(0).toUpperCase() + s.substring(1))
  }
  return arr.join(' ')
}

export function firstLetterToUppercase(str) {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export function checkAdminAccess(user) {
  return (user.roles.find(x => x === USER_ACCESS.ADMIN))
}

export function checkInstructorAccess(user) {
  return (user.roles.find(x => x === USER_ACCESS.INSTRUCTOR))
}

export function createClassSequenceFromData(data, sequences) {

  const prefixedUUID = createPrefixedUUID('SID')
  Object.assign(data, {sequenceId: prefixedUUID})
  const arr = [data] // ADD ORIGINAL DATA
  const sequenceStartMillis = createDateSequence(new Date(data.timeRange.classStart), sequences, oneWeekMills)
  const sequenceEndMillis = createDateSequence(new Date(data.timeRange.classEnd), sequences, oneWeekMills)

  for (let i = 0; i < (sequences); i++) {
    const newClass = {...data}
    newClass.timeRange = {
      classStart: sequenceStartMillis[i],
      classLength: data.timeRange.classLength,
      classEnd: sequenceEndMillis[i],
    }
    arr.push(newClass)
  }

  return arr

}

export function createDateSequence(startDate, sequence, millisAdd) {
  return Array.from({length: sequence}, (v, i) => i + 1).map(x => {
    return new Date(startDate.getTime() + (millisAdd * x)).setHours(startDate.getHours(), startDate.getMinutes(), 0, 0)
  })
}

export function isTimeWithinSetHours(epochMillis, intHours) {
  return new Date().getTime() < new Date(epochMillis - (intHours * 3600000)).getTime();
}
//
// export function getAvailableSlots(bookedUsers, maxSlots) {
//   return maxSlots - bookedUsers
// }

export function getAvailableSlots(bookedUsers, maxSlots) {
  return maxSlots - bookedUsers?.length
}

export function getAvailableSlotsByNum(bookedUsersNum, maxSlots) {
  return maxSlots - bookedUsersNum
}

export function isAttendanceAvailable(bookedUsers) {
  return bookedUsers?.length === 0
}

export function hasClassStarted(classStartMillis) {
  return new Date() > new Date(classStartMillis)
}

export function hasClassEnded(classStartMillis) {
  return new Date() < new Date(classStartMillis)
}

export function checkedListAssignBoolean(listChecked, data) {
  const indexOfDoc = listChecked.findIndex(x => (x).uid === data.user.uid)
  const findUserInChecked = listChecked.find(x => (x).uid === data.user.uid)
  const arrCopy = listChecked.slice()
  const newObj = Object.assign({...findUserInChecked}, {checked: data.checked})
  arrCopy.splice(indexOfDoc, 1, newObj)
  return arrCopy
}

export function assignUserRoles(admin, instructor) {
  const array = ['USER']
  if (admin) array.push('ADMIN')
  if (instructor) array.push('INSTRUCTOR')
  return array
}


export function checkEventBookingAvailability(bookedUsers, currentUserUID, maxSlots) {

  const isUserBooked = bookedUsers.find(x => (x).uid === currentUserUID)
  const isBookingAvailable = (getAvailableSlots(bookedUsers, maxSlots) === 0)

  return {
    isBooked: !!isUserBooked,
    isBookingAvailable: isBookingAvailable,
  }

}

const getLongDate = (epochTime) => {
  const options = {weekday: 'long', month: 'long', day: 'numeric'}
  return new Date(parseInt(epochTime)).toLocaleDateString('sv-SE', options)
}

export const displayHttpResponseMessage = (payload) => {

  if (payload.type === "FULFILLED") return message.success(payload.message)
  if (payload.type === "REJECTED") return message.warn(payload.message)
  return message.info(payload.message)

}

export function searchParamAsArray(value) {
  const spl = value.toLowerCase().trimRight().split(" ")
  return spl.filter((x) => x.length !== 0)
}

export function getEmailSearchParams(value) {
  return value.toLowerCase().trimRight()
     .split("@").join()
     .split(".").join()
     .split(",")
     .filter(x => x.length !== 0)
     .map(c => c.toLowerCase())
}

export function createReactKeyId(prefix) {
  const d = 'xxxxxxxx-xxxxxxx'.replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  return prefix + '-' + d.toString()
}
