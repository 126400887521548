import React from "react";
import Admin from "../components/Admin/Admin";

const PageAdmin = () => {

  return (
     <React.Fragment>
       <Admin/>
     </React.Fragment>
  )

}
export default PageAdmin;
