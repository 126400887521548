import React from 'react'
import {QuickNavigationButton} from "../SharedComponents/Basic/ActionButton";

const Admin = () => {

  return (
     <div style={{
       display: 'flex',
       flexDirection: 'column',
       width: '100%',
       gap: '0.5rem',
       alignItems: 'center',
       // background: 'red',
       maxWidth: 600
     }}>

       <QuickNavigationButton text={'Aktiva pass'} path={'/admin/all-classes'}/>
       <QuickNavigationButton text={'Aktiva events'} path={'/admin/events'}/>
       <QuickNavigationButton text={'Skapa ett event'} path={'/admin/create-event'}/>
       <QuickNavigationButton text={'Sök medlemmar'} path={'/admin/search-users'}/>

     </div>
  )
}


export default Admin;
