import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userData} from "../../features/firebase/firestore/firestoreCurrentUserData";
import {
  checkEventBookingAvailability,
  createReactKeyId,
  displayHttpResponseMessage,
  epochToHHMMStr, firstLetterToUppercase,
  getAvailableSlots,
  getDetailedDateTime,
  getTimeRangeLongTitleCased,
  isEpochMillisInFuture,
  minutesBetweenDates
} from "../../util";
import {addEventBooking, removeEventBooking} from "../../features/firebase/firestore/firestoreEventsSlice";
import {Button, Divider, message, Modal} from "antd";
import {UsePopConfirm} from "../SharedComponents/Input";
import {DeleteFilled, IdcardOutlined} from "@ant-design/icons";
import {lang} from "../../config/config";
import {IC_CALENDER, IC_CALENDER_COMPACT, IC_CARD, IC_CLOCK, IC_LOCATION, IC_SLOTS} from "../Icons/Icons";
import './Events.css'
import {CardForm} from "../SharedComponents/View";

export const EventBookingActionsRow = ({item}) => {

  const dispatch = useDispatch()
  const user = useSelector(userData)
  const availability = checkEventBookingAvailability(item.bookedUsers, user.uid, item.slotRange.total)
  const [displayModal, setDisplayModal] = useState(false)

  const onBookClick = () => {
    // console.log('EventBookingActionsRow:::onBookClick:::', item)
    dispatch(addEventBooking({
      documentId: item.documentId,
      data: {
        displayName: user.displayName,
        uid: user.uid,
        bookedAt: new Date().getTime(),
        email: user.email
      }
    })).then((response) => {
      message.success('Event har blivit bokat') //PROPER MESSAGE FROM RESPONSE
    })
  }

  const showModal = () => {
    setDisplayModal(!displayModal)
  };

  const btnBookDisplay = !availability.isBookingAvailable && !availability.isBooked ?
     <Button key={`button-booking-${item.documentId}`} onClick={showModal} type={'text'}>{lang.BOOK}</Button>
     : null

  const displayHasBooked = availability.isBooked ?
     <div className={'Booking-info-label'} style={{background: 'rgba(93,182,141, 0.8)'}}>{lang.SLOT_BOOKED}</div> : null //ICON?

  return !isEpochMillisInFuture(user.disabledUntil) ? (
     <React.Fragment>
       {btnBookDisplay}
       {displayHasBooked}
       <Modal
          title={`Bokning av event`}
          centered
          visible={displayModal}
          onOk={() => {
            showModal()
            onBookClick()
          }}
          onCancel={() => showModal()}
          okText={lang.BOOK}
          cancelText={lang.ABORT}
       >
         <p><i>Avbokning av event kan endast ske via kontakt med administratör.</i></p>
         <p>Fortsätt med bokning genom att klicka på boka nedan.</p>
       </Modal>

     </React.Fragment>
  ) : <div className={'Booking-info-label-disabled'}>{lang.USER_BLOCKED}</div>

}
export const EventItem = ({item}) => {

  const title = `${item.name}`
  const dated = `${getTimeRangeLongTitleCased(item.timeRange.eventStart)}`
  const slotRange = `${getAvailableSlots(item.bookedUsers, item.slotRange.total)} av ${item.slotRange.total} lediga`
  const classStartEnd = `${epochToHHMMStr(item.timeRange.eventStart)} - ${epochToHHMMStr(item.timeRange.eventEnd)}`
  const classLength = `${minutesBetweenDates(item.timeRange.eventStart, item.timeRange.eventEnd)} minuter`
  const description = `${firstLetterToUppercase(item.description)}`
  const cost = `${item.cost}`

  return item ? (
     <div style={{display: 'flex', flexDirection: 'column'}}>
       <div className={'EventItem name'}>
         {title}
       </div>

       <Divider style={{marginTop: 5, marginBottom: 10}}/>

       <div className={'EventItem date'}>
         {IC_CALENDER}{dated}
       </div>

       <div className={'EventItem time'}>
         <div>{IC_CLOCK}{classStartEnd}</div>
         <div>{classLength}</div>
       </div>

       <div className={'EventItem slots'}>
         <div>{IC_SLOTS}{slotRange}</div>
       </div>

       <div className={'EventItem payment'}>
         <div>{IC_CARD}{cost} kr</div>
       </div>

       <div className={'EventItem location'}>
         <div>{IC_LOCATION}{item?.location}</div>
       </div>

       <div className={'EventItem description'}>
         <blockquote>{description}</blockquote>
       </div>

     </div>
  ) : null
}
export const EventBookedUsersEdit = ({eventItem}) => {

  const dispatch = useDispatch()

  const onRemoveBookingClick = (booking) => {

    // console.log(booking)
    dispatch(removeEventBooking({
      documentRef: eventItem,
      data: {...booking}
    })).then((response) => {
      displayHttpResponseMessage(response.payload)
    })
  }

  return (
     <CardForm
        key={`${createReactKeyId('card-form')}`}
        settings={{
          title: lang.BOOKED_USERS,
          icon: <IdcardOutlined/>
        }}
        components={[
          <div className={'Booked-users-event-edit'}>
            {eventItem?.bookedUsers.length !== 0 ?
               eventItem?.bookedUsers?.map((booking, index) => (
                  <div className={'Booked-users-event-edit item'}>

                    <b>{index + 1}. {booking.displayName}</b>
                    <i>{booking.email}</i>
                    <p>{IC_CALENDER_COMPACT}{getDetailedDateTime(booking.bookedAt)}</p>

                    <div>
                      <UsePopConfirm
                         type={'danger'}
                         icon={<DeleteFilled style={{color: 'red'}}/>}
                         onClick={() => onRemoveBookingClick(booking)}
                         titleText={lang.ASK_REMOVE_BOOKING_EVENT}
                         components={[
                           <Button danger type={'default'}>{lang.REMOVE}</Button>,
                         ]}/>
                    </div>

                    <Divider dashed style={{marginTop: 15, marginBottom: 5}}/>

                  </div>
               )) : <i>Ingen har bokat en plats än!</i>
            }
          </div>
        ]}
     />
  )
}
