import React from "react";
import {useSelector} from "react-redux";
import {listOfAllActiveClasses} from "../../features/firebase/firestore/firestoreClassesSlice";
import {CardClasses, NoContent} from "../SharedComponents/View";
import {ClassItem, ConfigRowInstructor} from "./index";
import {QuickNavigationButton} from "../SharedComponents/Basic/ActionButton";
import {lang} from "../../config/config";
import {Spin} from "antd";
import {createReactKeyId} from "../../util";
import {userData} from "../../features/firebase/firestore/firestoreCurrentUserData";

const MyClasses = () => {

  const user = useSelector(userData)
  const allActiveClasses = useSelector(listOfAllActiveClasses)

  return allActiveClasses?.length !== 0 ? (
        <div className={'Class-list-container'}>
          {
            allActiveClasses?.filter(x => (x).instructor.uid === user.uid)?.map((item) => (
               <CardClasses
                  key={`${createReactKeyId('card-classes')}`}
                  components={[
                    <ClassItem key={`${createReactKeyId('card-form-class-item')}`} item={item}/>,
                  ]}
                  configComponents={[
                    <ConfigRowInstructor key={`${createReactKeyId('cfg-instructor')}`} item={item}/>
                  ]}
                  buttonRow={[]}
               />
            ))
          }
        </div>
     )
     : allActiveClasses?.length === 0 ?
        <NoContent {...{
          title: lang.NO_CLASSES_FOUND,
          description: lang.DESCRIPTION_NO_CLASSES_FOUND,
          components: [
            <QuickNavigationButton key={`${createReactKeyId('no-qnb')}`} path={'/create-class'}
                                   text={lang.CREATE_CLASS}/>,
          ]
        }}/>
        : <Spin/>

}
export default MyClasses;


