import React, {useContext} from "react";
import {Route} from "react-router-dom";
import {checkAccess, routes} from "./routes";
import {AuthContext} from "../components/Firebase/Auth/FirebaseAuthContext";
import {routesPublic} from "./routesPublic";
import {CSSTransition} from "react-transition-group";

export const RouterContent = () => {

  const user = useContext(AuthContext)

  return user ? (
        <React.Fragment>
          {routes.map(({path, Component, exact, requiredAccess}) => (

             <Route key={path} exact={exact} path={path}>
               {({match}) => (
                  !checkAccess(user, requiredAccess) ?
                     <CSSTransition
                        in={match != null}
                        timeout={300}
                        classNames="content"
                        unmountOnExit
                     >
                       <div className={"Content"}>
                         {Component}
                       </div>
                     </CSSTransition>
                     : null
               )}
             </Route>
          ))}
        </React.Fragment>
     )
     :
     <div className={"Content"}>
       {routesPublic.map(({path, Component, exact}) => (

          <Route key={path} exact={exact} path={path}>
            {
              <div className={"Content"}>
                {Component}
              </div>
            }
          </Route>
       ))}
     </div>

}
