import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {firestoreAPI} from "./firestoreAPI";
import {addDocumentToArray, modifyDocumentInArray, removeDocumentFromArray} from "./index";

const initialState = {
  LIST: [],
  LIST_ALL_ACTIVE_CLASSES: [],
  MESSAGE: '',
};

//SNAPSHOT-LISTENER INSTEAD OF GET?
export const getClasses = createAsyncThunk('firestoreClassesSlice/getClasses', async () => {
  return await firestoreAPI.getClasses()
});

//USER CRUD =>
export const addBooking = createAsyncThunk('firestoreClassesSlice/addBooking', async (data) => {
  return await firestoreAPI.addBooking(data)
});
export const removeBooking = createAsyncThunk('firestoreClassesSlice/removeBooking', async (data) => {
  return await firestoreAPI.removeBooking(data)
});
export const addReserveBooking = createAsyncThunk('firestoreClassesSlice/addReserveBooking', async (data) => {
  return await firestoreAPI.addReserveBooking(data)
});
export const removeBookingReserved = createAsyncThunk('firestoreClassesSlice/removeReserveBooking', async (data) => {
  return await firestoreAPI.removeBookingReserved(data)
});

//INSTRUCTOR CRUD =>
export const createClass = createAsyncThunk('firestoreClassesSlice/createClass', async (data) => {
  return await firestoreAPI.createClass(data)
});
export const createClassSequence = createAsyncThunk('firestoreClassesSlice/createClassSequence', async (data) => {
  return await firestoreAPI.createClassSequence(data)
});
export const updateClass = createAsyncThunk('firestoreClassesSlice/updateClass', async (data) => {
  return await firestoreAPI.updateClass(data)
});
export const updateClassAttendance = createAsyncThunk('firestoreClassesSlice/updateClassAttendance', async (data) => {
  return await firestoreAPI.updateClassAttendance(data)
})
export const addClassGuestBookings = createAsyncThunk('firestoreClassesSlice/addGuestBookings', async (data) => {
  return await firestoreAPI.addGuestBookings(Object.assign(data, {type: 'classBookings'}))
});

//ADMIN CRUD =>
export const removeBookingAdmin = createAsyncThunk('firestoreClassesSlice/removeBookingAdmin', async (data) => {
  return await firestoreAPI.removeBookingAdmin(data)
});
export const removeBookingReservedAdmin = createAsyncThunk('firestoreClassesSlice/removeBookingReservedAdmin', async (data) => {
  return await firestoreAPI.removeReservedBookingAdmin(data)
});
export const removeClass = createAsyncThunk('firestoreClassesSlice/removeClass', async (data) => {
  return await firestoreAPI.removeClass(data)
});

export const firestoreClassesSlice = createSlice({
  name: 'firestoreClassesSlice',
  initialState,
  reducers: {
    documentFromSnapListener: (state, action) => {

      const docData = action.payload.docData

      if (action.payload.changeType === "added") {
        try {
          if (action.payload.listTarget === "LIST") {
            state.LIST = addDocumentToArray(state.LIST, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }
      if (action.payload.changeType === "modified") {
        try {
          if (action.payload.listTarget === "LIST") {
            state.LIST = modifyDocumentInArray(state.LIST, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }
      if (action.payload.changeType === "removed") {
        try {
          if (action.payload.listTarget === "LIST") {
            state.LIST = removeDocumentFromArray(state.LIST, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }


    },
    documentFrom: (state, action) => {

      const docData = action.payload.docData

      if (action.payload.changeType === "added") {
        try {
          if (action.payload.listTarget === "LIST_ALL_ACTIVE_CLASSES") {
            state.LIST_ALL_ACTIVE_CLASSES = addDocumentToArray(state.LIST_ALL_ACTIVE_CLASSES, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }

      }
      if (action.payload.changeType === "modified") {
        try {
          if (action.payload.listTarget === "LIST_ALL_ACTIVE_CLASSES") {
            state.LIST_ALL_ACTIVE_CLASSES = modifyDocumentInArray(state.LIST_ALL_ACTIVE_CLASSES, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }
      if (action.payload.changeType === "removed") {
        try {
          if (action.payload.listTarget === "LIST_ALL_ACTIVE_CLASSES") {
            state.LIST_ALL_ACTIVE_CLASSES = removeDocumentFromArray(state.LIST_ALL_ACTIVE_CLASSES, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }
    },
    resetStates: (state) => {
      state.LIST = []
      state.LIST_ALL_ACTIVE_CLASSES = []
    },
  },

  extraReducers: (builder) => {
    builder
       .addCase(getClasses.fulfilled, (state, action) => {
         // console.log('ACTION PAYLOAD::CLASSES::FULFILLED::', action.payload)
         console.log(action.payload)
         state.LIST = action.payload
       })
       .addCase(getClasses.rejected, (state) => {
         state.LIST = []
       })

  },

});


export const {
  documentFromSnapListener,
  documentFrom,
  resetStates,
} = firestoreClassesSlice.actions;
export const listOfClasses = (state) => state.firestoreClasses.LIST;
export const listOfAllActiveClasses = (state) => state.firestoreClasses.LIST_ALL_ACTIVE_CLASSES;
export const classesMessage = (state) => state.firestoreClasses.MESSAGE;


export default firestoreClassesSlice.reducer;
