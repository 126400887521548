import {getTimeRangeLongTitleCased, getTimeRangeString, titleCase} from "../../util";
import React from "react";
import './Calendar.css'

export const CalendarEventItems = ({arr}) => {

  return (
     <div className={'Calendar-item-container'}>
       <div className={'Calendar-item-container sub-container'}>
         {arr?.map((c) => (
            <div key={`calendar-item ${c.documentId}`} className={'Calendar-item-class-container item'}>
              <b>{titleCase(c.name)}</b>
              <b>{getTimeRangeLongTitleCased(c.timeRange.eventStart)}</b>
              <b>{getTimeRangeString(c.timeRange.eventStart, c.timeRange.eventEnd)}</b>
              <b>
                {c?.location ? titleCase(c.location) : c.location}
              </b>

            </div>
         ))
         }
       </div>
     </div>
  )
}

