import React from "react";
import EventsList from "../components/Events/EventsList";


const PageEvents = () => {

  return (
     <React.Fragment>
       <EventsList/>
     </React.Fragment>
  )

}
export default PageEvents;
