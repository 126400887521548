import React from "react";
import Profile from "../components/Profile/Profile";


const PageProfile = () => {

  return (
     <React.Fragment>
       <Profile/>
     </React.Fragment>
  )

}
export default PageProfile;
