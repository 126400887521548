import React from "react";
import AdminActiveClasses from "../components/Admin/AdminActiveClasses";

const PageAdminActiveClasses = () => {

  return (
     <React.Fragment>
       <AdminActiveClasses/>
     </React.Fragment>
  )

}
export default PageAdminActiveClasses;
