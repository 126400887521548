import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {firestoreAPI} from "./firestoreAPI";
import {addDocumentToArray, modifyDocumentInArray, removeDocumentFromArray} from "./index";

const initialState = {
  LIST_EVENTS: [],
  loading: false
};

//CREATE =>
export const createEventDocument = createAsyncThunk('firestoreEventsSlice/createEventDocument', async (data) => {
  return await firestoreAPI.createEvent(data)
});

//READS =>
export const getEventDocuments = createAsyncThunk('firestoreEventsSlice/getEventDocuments', async () => {
  return firestoreAPI.getEvents()
});

//UPDATE =>
export const updateEventDocument = createAsyncThunk('firestoreEventsSlice/updateEventDocument', async (data) => {
  return await firestoreAPI.updateEvent(data)
});
export const removeEventBooking = createAsyncThunk('firestoreEventsSlice/removeEventBooking', async (data) => {
  return await firestoreAPI.removeEventBooking(data)
});
export const addEventBooking = createAsyncThunk('firestoreEventsSlice/addEventBooking', async (data) => {
  return await firestoreAPI.addEventBooking(data)
});

//DELETE =>
export const removeEventDocument = createAsyncThunk('firestoreEventsSlice/removeEventDocument', async (data) => {
  return await firestoreAPI.removeEvent(data)
});

export const addEventGuestBookings = createAsyncThunk('firestoreEventsSlice/addEventGuestBookings', async (data) => {
  return await firestoreAPI.addGuestBookings(Object.assign(data, {type: 'eventBookings'}))
});

export const firestoreEventsSlice = createSlice({
  name: 'firestoreEventsSlice',
  initialState,
  reducers: {
    eventDocumentFromSnapListener: (state, action) => {

      const docData = action.payload.docData

      if (action.payload.changeType === "added") {
        try {
          if (action.payload.listTarget === "LIST_EVENTS") {
            state.LIST_EVENTS = addDocumentToArray(state.LIST_EVENTS, docData)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
        }
      }
      if (action.payload.changeType === "modified") {
        try {
          if (action.payload.listTarget === "LIST_EVENTS") {
            state.LIST_EVENTS = modifyDocumentInArray(state.LIST_EVENTS, docData)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }
      if (action.payload.changeType === "removed") {
        try {
          if (action.payload.listTarget === "LIST_EVENTS") {
            state.LIST_EVENTS = removeDocumentFromArray(state.LIST_EVENTS, docData)
            // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`)
          }
        } catch (e) {
          // console.log(`firestoreClassesSlice:::${action.payload.changeType}:::${action.payload.listTarget}:::${docData}`, e)
        }
      }
    },
    resetEventsStates: (state) => {
      state.LIST_EVENTS = []
    },
  },
  extraReducers: (builder) => {
    builder
       .addCase(getEventDocuments.pending, (state) => {
         state.loading = true
       })
       .addCase(getEventDocuments.fulfilled, (state, action) => {
         state.loading = false
         state.LIST_EVENTS = JSON.parse(JSON.stringify(action.payload.data))
       })
       .addCase(getEventDocuments.rejected, (state) => {
         state.loading = false
         state.LIST_EVENTS = []
       })
  },
});

export const {
  eventDocumentFromSnapListener,
  resetEventsStates
} = firestoreEventsSlice.actions;

export const listOfEvents = (state) => state.firestoreEvents.LIST_EVENTS;
export const eventsLoading = (state) => state.firestoreEvents.loading;

export default firestoreEventsSlice.reducer;
