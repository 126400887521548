import React from 'react'
import './ActionButtons.css'
import {useHistory} from "react-router-dom";

const types = [
  {
    type: 'apply',
    className: 'btn-apply'
  },
  {
    type: 'remove',
    className: 'btn-remove'
  },
  {
    type: 'navigate',
    className: 'btn-navigate'
  },
]

function getButtonType(type) {
  return type ? types.find(x => (x).type === type).className : 'action-button'
}

export const ActionButton = ({actiontype, style, type, text, onClick, compact}) => {

  return (
     <button
        style={style ? {...style} : null}
        className={compact ? `Action-button-compact ${getButtonType(actiontype)}` : `Action-button ${getButtonType(actiontype)}`}
        type={type ? type : 'button'}
        onClick={() => {
          onClick()
        }}
     >
       <label>{text}</label>
     </button>
  )

}

export const QuickNavigationButton = ({text, path}) => {

  const history = useHistory()

  const navigatePath = () => {
    history.push(path)
  }

  return (
     <button
        type={'button'}
        className={`Action-button btn-navigate`}
        onClick={() => {
          navigatePath()
        }}
     >
       <label>{text}</label>
     </button>
  )

}
