import React, {createContext, useEffect, useState} from "react";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import {collection, doc, getDoc, getFirestore} from "firebase/firestore";
import {SignedInUser} from "../../../features/firebase/firestore/data/classes";

import {getInstructors} from "../../../features/firebase/firestore/firestoreUsersSlice";
import {firestoreAPI} from "../../../features/firebase/firestore/firestoreAPI";
import {resetStates} from "../../../features/firebase/firestore/firestoreClassesSlice";
import {resetEventsStates} from "../../../features/firebase/firestore/firestoreEventsSlice";

export const AuthContext = createContext(null)

const FirebaseAuthContext = (props) => {

  const [userState, setUserState] = useState({
    authUser: null,
    authListener: null,
  })

  const getUserData = async (uid) => {
    const docSnap = await getDoc(doc(collection(getFirestore(), "users"), uid));
    let data = null
    if (docSnap.exists()) {
      data = docSnap.data()
    }
    return data
  }

  useEffect(() => {
    console.log('This is FirebaseAuthContext talking!')

    if (userState.authListener == null) {

      setUserState({
        ...userState,
        authListener:
           onAuthStateChanged(getAuth(), async (user) => {
             if (user) {

               firestoreAPI.addUserDocListener(user.uid, props.dispatch)

               const userDocData = await getUserData(user.uid)
               const signedInUser = SignedInUser(
                  user.uid,
                  user.email,
                  user.displayName,
                  user.phoneNumber,
                  userDocData,
               )

               //NOTE: currently resetting LIST and LIST_ALL_ACTIVE_CLASSES in firebaseClassesSlice & events
               props.dispatch(resetStates())
               props.dispatch(resetEventsStates())


               if (userDocData?.roles?.includes('USER')) {
                 // console.log('TRUE USER')
                 firestoreAPI.attachClassesSnapshotListener(props.dispatch)
                 firestoreAPI.attachEventsSnapshotListener(props.dispatch)
               }
               //
               if (userDocData?.roles?.includes('INSTRUCTOR')) {
                 // console.log('TRUE INSTRUCTOR')
                 props.dispatch(getInstructors()) //only GET SELF??
                 firestoreAPI.attachActiveClassesSnapshotListener(props.dispatch)
               }
               //
               if (userDocData?.roles?.includes('ADMIN')) {
                 // console.log('TRUE ADMIN')
               }

               setUserState({
                 ...userState,
                 authUser: signedInUser
               })
             } else {
               props.dispatch(resetStates())
               props.dispatch(resetEventsStates())
               setUserState({
                    ...userState,
                    authUser: null
                  }
               )
             }
           })
      })


    }
    // CLEANUP LISTENER
    // return () => {
    //   if (userState.authListener)
    //     console.log('cleaning upppppp')
    // }


  }, [props.dispatch])

  return (
     <AuthContext.Provider value={userState.authUser}>
       {props.children}
     </AuthContext.Provider>
  )
}

export default FirebaseAuthContext
