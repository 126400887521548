import {CardForm} from "../View";
import {lang} from "../../../config/config";
import {UsergroupAddOutlined} from "@ant-design/icons";
import {Button, Form, message} from "antd";
import React, {useState} from "react";
import {InputEmail, InputGuestCount, InputName} from "./index";
import {createGuestBooking} from "../../../util";
import {useDispatch} from "react-redux";
import {addClassGuestBookings} from "../../../features/firebase/firestore/firestoreClassesSlice";
import {addEventGuestBookings} from "../../../features/firebase/firestore/firestoreEventsSlice";

const BookGuestsForm = ({item, itemType}) => {

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [charCount, setCharCount] = useState(0)

  const onFinishFailed = () => {
  }

  const onFinish = () => {
    const data = createGuestBooking(
       form.getFieldValue('guest_count'),
       item.bookedUsers,
       form.getFieldValue('email'),
       form.getFieldValue('name')
    )

    if (itemType === "classBookings") {
      addToClassBookings(data)
    } else if (itemType === "eventBookings") {
      addToEventBookings(data)
    }
  }

  const addToClassBookings = (data) => {
    dispatch(addClassGuestBookings({
      documentId: item.documentId,
      data: data
    })).then(() => {
      message.info('Gäster inbokade!')
      form.resetFields()
      setCharCount(0)
    })
  }

  const addToEventBookings = (data) => {
    dispatch(addEventGuestBookings({
      documentId: item.documentId,
      data: data
    })).then(() => {
      message.info('Gäster inbokade!')
      form.resetFields()
      setCharCount(0)
    })
  }

  const onFinishS = () => {
    form.validateFields()
       .then(() => {
         onFinish()
       })
       .catch(() => {
         onFinishFailed()
       })
  }

  return (
     <Form
        name={'form_book_guests'}
        layout={'vertical'}
        form={form}
        onFinishFailed={onFinishFailed}
        style={{width: '100%'}}
     >
       <CardForm
          settings={{
            title: lang.BOOK_GUESTS,
            icon: <UsergroupAddOutlined/>
          }}
          components={[
            <InputName charCount={charCount} setCharCount={setCharCount}/>,
            <InputGuestCount bookedUsers={item?.bookedUsers ? item.bookedUsers : 0} maxSlots={item?.slotRange.total}/>,
            <InputEmail/>,
          ]}
          buttonRow={[
            <Button type={'primary'} onClick={() => onFinishS()}>{lang.BOOK_GUESTS}</Button>,
          ]}
       />
     </Form>
  )
}

export default BookGuestsForm
