import {CardForm} from "../../../SharedComponents/View";
import {lang} from "../../../../config/config";
import {UserOutlined} from "@ant-design/icons";
import {Button, Divider, Form} from "antd";
import React, {useState} from "react";
import {firebaseAuthAPI} from "../../../../features/firebase/auth/firebaseAuthAPI";
import {getEmailSearchParams} from "../../../../util";
import {useHistory} from "react-router-dom";
import {
  SignUpConfirmPasswordInput,
  SignUpEmailInput,
  SignUpFirstNameInput,
  SignUpLastNameInput,
  SignUpPasswordInput,
  SignUpPhoneInput
} from "./index";
import {SignInUpScreenLogo} from "../index";

export const AuthSignUpForm = () => {

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()

  const onFinishFailed = () => {}

  const onFinish = () => {
    setIsLoading(true)
    firebaseAuthAPI.signUpUser(
       history,
       form.getFieldValue('email'),
       form.getFieldValue('password'),
       {
         data: {
           displayName: `${form.getFieldValue('firstname')} ${form.getFieldValue('lastname')}`,
           phoneNumber: `${form.getFieldValue('phone')}`,
           searchParams: [
             form.getFieldValue('firstname').toLowerCase(),
             form.getFieldValue('lastname').toLowerCase(),
             `${form.getFieldValue('phone')}`,
             ...getEmailSearchParams(form.getFieldValue('email'))
           ],
         }
       }
    ).then(() => {
      setIsLoading(false)
    }).finally(() => {
        history.push('/welcome')
    })
  }


  return (
     <div className={'Auth-container'}>
       <Form
          name={'form_profile_email'}
          layout={'vertical'}
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
       >
         <CardForm
            settings={{title: lang.CREATE_ACCOUNT, icon: <UserOutlined/>}}
            components={[
              <SignInUpScreenLogo/>,
              <SignUpEmailInput/>,
              <SignUpPasswordInput/>,
              <SignUpConfirmPasswordInput/>,
              <SignUpFirstNameInput/>,
              <SignUpLastNameInput/>,
              <SignUpPhoneInput/>,
              <Divider dashed/>,
              <Button loading={isLoading} htmlType={'submit'}>{lang.CREATE_ACCOUNT}</Button>,
            ]}
         />
       </Form>
     </div>
  )

}


