import React, {useState} from "react";
import {Button, Divider, Form} from "antd";
import {CardForm} from "../SharedComponents/View";
import {ScheduleOutlined} from "@ant-design/icons";
import {
  BookedUsersEdit,
  InputCategory,
  InputClassName,
  InputClassTime,
  InputDescription,
  InputInstructor,
  InputLengthMinutes,
  InputLocation,
  InputMaxSlots,
  InputStartDate,
  ReservedBookingEdit,
  SequenceClass
} from "./index";
import {createClassUploadData, displayHttpResponseMessage, findCategory, findObjKeyValue} from "../../util";
import {useDispatch, useSelector} from "react-redux";
import './Classes.css'
import {CLASSES_CATEGORIES_ARR, CLASSES_LENGTH_ARR, lang, langResponses} from "../../config/config";
import {
  createClass,
  createClassSequence,
  listOfAllActiveClasses,
  updateClass
} from "../../features/firebase/firestore/firestoreClassesSlice";
import {listOfInstructors} from "../../features/firebase/firestore/firestoreUsersSlice";
import moment from "moment";
import {userData} from "../../features/firebase/firestore/firestoreCurrentUserData";
import {UsePopConfirm} from "../SharedComponents/Input";
import BookGuestsForm from "../SharedComponents/Input/BookGuestsForm";


const ClassForm = ({edit, classId}) => {

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [editMode] = useState(edit)
  const user = useSelector(userData)
  const listClasses = useSelector(listOfAllActiveClasses)
  const listInstructors = useSelector(listOfInstructors ? listOfInstructors : [])
  const [sequenceCheck, setSequenceCheck] = useState(false)
  const [isUploading, setIsUploading] = useState(false)

  const findClass = editMode ? listClasses.find(x => (x).documentId === classId) : null
  const findCat = editMode ? findCategory(findClass?.category.value) : null
  const findClassStart = editMode ? findClass?.timeRange.classStart : null

  const onFinishFailed = () => {
    // console.log(form.getFieldsValue())
  }

  const onFinish = () => {
    // console.log(form.getFieldsValue())

    const data = createClassUploadData(
       form.getFieldValue('startDate')._d,
       form.getFieldValue('endTime')._d,
       form.getFieldValue('lengthMinutes'),
       form.getFieldValue('category'),
       form.getFieldValue('description'),
       form.getFieldValue('slots'),
       user.uid,
       findObjKeyValue(listInstructors, "displayName", form.getFieldValue('instructor')),
       form.getFieldValue('name'),
       form.getFieldValue('location'),
    )

    editMode
       ? updateDocument(data) :
       !sequenceCheck
          ? addDocument(data) : addDocumentSequence(data, form.getFieldValue('sequences'))

  }

  const onCheckedChange = () => {
    setSequenceCheck(!sequenceCheck)
  }

  // console.log(sequenceCheck)

  const addDocument = (data) => {
    setIsUploading(true)
    dispatch(createClass(data)).then((response) => {
      setIsUploading(false)
      displayHttpResponseMessage(response.payload).then(() => {
        if (response.payload.type === langResponses.FULFILLED) {
          form.resetFields()
        }
      })
    })
  }

  const addDocumentSequence = (data, sequences) => {
    setIsUploading(true)
    dispatch(createClassSequence({sequences: sequences, data: data})).then((response) => {
      displayHttpResponseMessage(response.payload).then(() => {
        setIsUploading(false)
        if (response.payload.type === langResponses.FULFILLED) {
          form.resetFields()
        }
      })
    })
  }

  const updateDocument = (data) => {
    delete data.bookedUsers
    delete data.createdBy

    setIsUploading(true)

    dispatch(updateClass({
      documentId: classId,
      data: data
    })).then((response) => {
      setIsUploading(false)
      displayHttpResponseMessage(response.payload)
    })

  }

  return (
     <div className={!editMode ? 'Class-form-container' : 'Class-form-container-edit-mode'}>
       <Form
          name={'form_create_class'}
          layout={'vertical'}
          form={form}
          scrollToFirstError
          initialValues={
            {
              startDate: editMode ? moment(findClassStart) : moment(new Date()),
              endTime: editMode ? moment(findClassStart) : moment(new Date()),
              name: editMode ? findClass?.name : null,
              location: editMode ? findClass?.location : null,
            }
          }
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{width: '100%'}}
       >

         <CardForm
            settings={{title: editMode ? lang.EDIT_CLASS : lang.CREATE_CLASS, icon: <ScheduleOutlined/>}}
            components={[
              <InputClassName key={'input-name'} initialValue={editMode ? findClass?.name : null}/>,
              <InputDescription key={'input-description'} initialValue={editMode ? findClass?.description : null}/>,
              <InputLocation key={'input-location'} initialValue={editMode ? findClass?.location : null} />,
              <InputInstructor key={'input-instructor'} array={listInstructors}
                               initialValue={editMode ? findClass?.instructor.displayName : null}/>,
              <InputCategory key={'input-category'} array={CLASSES_CATEGORIES_ARR}
                             initialValue={editMode ? findCat : null}/>,
              <InputStartDate key={'input-start-date'}
                              initialValue={editMode ? findClass?.timeRange.classStart : null}/>,
              <InputClassTime key={'input-class-time'}/>,
              <InputLengthMinutes key={'input-length-minutes'} array={CLASSES_LENGTH_ARR}
                                  initialValue={editMode ? findClass?.timeRange.classLength : null}/>,
              <InputMaxSlots key={'input-max-slots'} initialValue={editMode ? findClass?.slotRange.total : null}/>,
              editMode ? null : <SequenceClass key={'input-sequence'} currentState={sequenceCheck}
                                               onStateChange={() => onCheckedChange}/>,
              <Divider key={'diver-dashed'} dashed/>,
            ]}
            buttonRow={[
              <UsePopConfirm
                 key={'popconfirm'}
                 onClick={() => {
                   form.validateFields()
                      .then(() => {
                        onFinish()
                      })
                      .catch(() => {
                        onFinishFailed()
                      })
                 }}
                 titleText={lang.ASK_CREATE_CLASS}
                 components={[
                   <Button
                      key={'class-form-btn-submit'}
                      type={'primary'}
                      loading={isUploading}>{editMode ? lang.EDIT_CLASS : lang.CREATE_CLASS}</Button>,
                 ]}/>,
            ]}
         />

       </Form>
       {editMode ? <BookedUsersEdit key={'class-form-booked-users-edit'} classItem={findClass}/> : null}
       {editMode ? <ReservedBookingEdit key={'class-form-reserved-users-edit'} classItem={findClass}/> : null}
       {editMode && (classId) ?
          <BookGuestsForm item={findClass} itemType={'classBookings'}/> : null}
     </div>
  )
}


export default ClassForm;
