import React from "react";
import ClassesList from "../components/Classes/ClassesList";

const PageClasses = () => {

  return (
     <React.Fragment>
       <ClassesList/>
     </React.Fragment>
  )

}
export default PageClasses;
