import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {firestoreAPI} from "./firestoreAPI";

const initialState = {
  LIST_CALENDAR: [],
  loading: false
};

export const getCalender = createAsyncThunk(
   'firestoreCalendarSlice/getCalender',
   async () => {
     return await firestoreAPI.getCalender()
   }
);

export const firestoreCalendarSlice = createSlice({
  name: 'firestoreCalendarSlice',
  initialState,
  reducers: {
    x: (state, action) => {

      try {

      } catch (e) {

      }

    },
  },
  extraReducers: (builder) => {
    builder
       .addCase(getCalender.pending, (state, action) => {
         state.loading = true
       })
       .addCase(getCalender.fulfilled, (state, action) => {
         state.loading = false
         // console.log('payload', action.payload.data)
         state.LIST_CALENDAR = JSON.parse(JSON.stringify(action.payload.data))
       })
       .addCase(getCalender.rejected, (state, action) => {
         state.loading = false
         state.LIST_CALENDAR = []
       })
  },

});

export const {x} = firestoreCalendarSlice.actions;
export const listCalendar = (state) => state.firestoreCalendar.LIST_CALENDAR;
export const calendarLoading = (state) => state.firestoreCalendar.loading;

export default firestoreCalendarSlice.reducer;
