import React, {useEffect, useState} from "react";
import {Button, Divider} from "antd";
import {
  displayHttpResponseMessage, firstLetterToUppercase,
  getTimeRangeLongTitleCased,
  getTimeRangeString,
  isTimeWithinSetHours
} from "../../../util";
import {lang} from "../../../config/config";
import {IC_CALENDER_COMPACT, IC_CLOCK_COMPACT, IC_CONTACTS_COMPACT} from "../../Icons/Icons";
import {useDispatch, useSelector} from "react-redux";
import {listOfClasses, removeBooking} from "../../../features/firebase/firestore/firestoreClassesSlice";
import {userData} from "../../../features/firebase/firestore/firestoreCurrentUserData";
import {UsePopConfirm} from "../../SharedComponents/Input";
import {DeleteFilled} from "@ant-design/icons";
import Add2Calendar from "add2calendar";
import './BookingsStyles.css'

const ProfileBookedClasses = () => {

  const user = useSelector(userData)
  const classes = useSelector(listOfClasses)
  const [bookedClasses, setBookedClasses] = useState([])

  useEffect(() => {
    const arr = []
    classes.forEach((classItem) => {
      if (classItem.bookedUsers?.find(x => x.uid === user.uid)) {
        arr.push(classItem)
      }
    })
    setBookedClasses(arr)
  }, [classes, user.uid])

  return bookedClasses?.length !== 0 ?
     <div style={{display: 'flex', flexDirection: 'column', maxHeight: 400, gap: '1rem', overflowY: 'scroll'}}>
       {/*{console.log('classes', classes.bookedUsers?.filter(x => (x).uid === user.uid))}*/}
       {bookedClasses?.map((item) => (
          <ClassItemCompact key={`class-item-compact-${item.documentId}`} item={item}/>
       ))}
     </div>
     : <i key={'empty'}>{lang.NO_BOOKED_CLASSES_USER}</i>
}

const ClassItemCompact = ({item}) => {

  const dispatch = useDispatch()
  const user = useSelector(userData)

  const dated = `${getTimeRangeLongTitleCased(item.timeRange.classStart)}`
  const timeStartEnd = `${getTimeRangeString(item.timeRange.classStart, item.timeRange.classEnd)}`
  const title = `${firstLetterToUppercase(item.name)}`
  const instructor = `${item.instructor.displayName}`

  const onRemoveBookingClick = () => {

    const findUserBooking = item.bookedUsers?.find(x => (x).uid === user.uid)

    dispatch(removeBooking({
      documentRef: item,
      data: {...findUserBooking}
    })).then((response) => {
      // console.log(response.payload)
      displayHttpResponseMessage(response.payload)
    })
  }

  return (
     <div
        key={`class-item-compact-${item.name}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'noWrap',
          alignItems: 'flex-start',
          minHeight: 200,
          gap: '0.3rem',
          marginLeft: 15,
          marginRight: 15,
        }}>

       <b>{title}</b>

       <div>
         {IC_CALENDER_COMPACT}{dated}
       </div>
       <div>
         {IC_CLOCK_COMPACT}{timeStartEnd}
       </div>
       <div>
         {IC_CONTACTS_COMPACT}{instructor}
       </div>

       <Divider key={'divider'} style={{marginTop: 10, marginBottom: 0}}/>

       <div style={{
         display: 'flex',
         flexWrap: 'wrap',
         width: '100%',
         height: '100%',
         gap: '0.5rem',
         marginTop: '1rem',
         marginBottom: '0.5rem'
       }}>
         {
           isTimeWithinSetHours(item.timeRange.classStart, 2) ?
              <UsePopConfirm
                 key={'popconfirm'}
                 type={'danger'}
                 icon={<DeleteFilled style={{color: 'red'}}/>}
                 onClick={() => onRemoveBookingClick()}
                 titleText={lang.ASK_REMOVE_BOOKING}
                 components={[
                   <Button danger key={`button-remove-booking-${item.documentId}`}
                           style={{marginTop: 0, marginBottom: 0}}
                           type={'default'}>{lang.BOOKING_CANCEL}</Button>,
                 ]}/>
              : <i>Avbokning måste göras 2 timmar innan pass-start</i>
         }
         <Add2CalendarComponent documentId={item.documentId} event={
           {
             title: title,
             start: item.timeRange.classStart,
             end: item.timeRange.classEnd,
             location: "Södra Stenungsön, 444 48 Stenungsund",
             description: item.description
           }
         }/>
       </div>

     </div>
  )
}

const Add2CalendarComponent = ({documentId, event}) => {

  let singleEvent = new Add2Calendar({
    title: event.title,
    start: event.start,
    end: event.end,
    location: event.location,
    description: event.description
  })

  useEffect(() => {

    singleEvent.defaultOption.buttonText = "+ Egen Kalender"
    singleEvent.createWidget('#single-normal-' + documentId)
  }, [documentId])

  return (
     <div id={`single-normal-${documentId}`}/>
  );
}

export default ProfileBookedClasses;
