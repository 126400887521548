import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {firestoreAPI} from "./firestoreAPI";

const initialState = {
  LIST: [],
  LIST_INSTRUCTORS: [],
  LIST_SEARCH_USERS: [],
  loading: true,
};

export const getInstructors = createAsyncThunk('firestoreUsersSlice/getInstructors', async () => {
  return firestoreAPI.getInstructors()
});
export const setMockInstructors = createAsyncThunk('firestoreUsersSlice/setMockInstructors', (data) => {
  return data
});
export const setMockUsers = createAsyncThunk('firestoreUsersSlice/setMockUsers', (data) => {
  return data
});


export const getUsers = createAsyncThunk(
   'firestoreUsersSlice/getUsers',
   async () => {

     return await firestoreAPI.getUsers()

   }
);

export const getUsersBySearchParam = createAsyncThunk(
   'firestoreUsersSlice/getUsersBySearchParam',
   async (data) => {
     return await firestoreAPI.getUsersBySearchParam(data)
   }
);

export const updateUser = createAsyncThunk('firestoreUsersSlice/updateUser', async (data) => {
  return await firestoreAPI.updateUser(data)
});


export const deleteUser = createAsyncThunk(
   'firestoreUsersSlice/deleteUser',
   async (data) => {
     return await firestoreAPI.deleteUser(data)
   }
);

export const firestoreUsersSlice = createSlice({
  name: 'firestoreUsersSlice',
  initialState,
  reducers: {
    userUpdate: (state, action) => {

      try {
        const indexOfDoc = state.LIST.findIndex((doc) => (doc).documentId === action.payload.documentId)
        const arrCopy = state.LIST.slice()
        arrCopy.splice(indexOfDoc, 1, action.payload)
        state.LIST = arrCopy
        // state.DOCUMENT_UPDATE_STATUS = FIRESTORE_API_RESPONSES.SUCCESS_UPDATING_DOC;
      } catch (e) {
        // state.DOCUMENT_UPDATE_STATUS = FIRESTORE_API_RESPONSES.ERROR_UPDATING_DOC;
        // console.log('firestoreUsersSlice:::documentModified::: Error trying to modify document in LIST', e)
      }

    },
  },

  extraReducers: (builder) => {
    builder
       .addCase(getInstructors.fulfilled, (state, action) => {
         state.LIST_INSTRUCTORS = action.payload.data;
       })
       .addCase(getInstructors.rejected, (state, action) => {
         state.LIST_INSTRUCTORS = []
         // console.log(action.payload.message)
       })
       .addCase(getUsersBySearchParam.pending, (state, action) => {
         state.LIST_SEARCH_USERS = []
       })
       .addCase(getUsersBySearchParam.fulfilled, (state, action) => {
         state.LIST_SEARCH_USERS = action.payload.data
       })
    // .addCase(getUsersBySearchParam.rejected, (state, action) => {
    //   state.LIST_SEARCH_USERS = []
    // })
    // .addCase(setMockInstructors.fulfilled, (state, action) => {
    //   state.LIST = action.payload;
    // })
    // .addCase(setMockInstructors.rejected, (state, action) => {
    //   state.LIST = []
    // })
    // .addCase(setMockUsers.fulfilled, (state, action) => {
    //   state.LIST = action.payload;
    // })
    // .addCase(setMockUsers.rejected, (state, action) => {
    //   state.LIST = []
    // })
  },

});

export const {
  userUpdate,
} = firestoreUsersSlice.actions;
export const listOfUsers = (state) => state.firestoreUsers.LIST;
export const listOfInstructors = (state) => state.firestoreUsers.LIST_INSTRUCTORS;
export const listOfSearches = (state) => state.firestoreUsers.LIST_SEARCH_USERS;

export default firestoreUsersSlice.reducer;
