import React from 'react';
import {CardClasses, NoContent} from "../SharedComponents/View";
import {Spin} from "antd";
import './Classes.css'
import {lang} from "../../config/config";
import {createReactKeyId} from "../../util";
import {useSelector} from "react-redux";
import {listOfClasses} from "../../features/firebase/firestore/firestoreClassesSlice";
import {BookingClassActionsRow, ClassItem, ClassOptionDisabledText} from "./index";

const ClassesList = () => {

  const classes = useSelector(listOfClasses)

  return classes?.length !== 0 ? (
     <div className={'Class-list-container'}>
       {
         classes.map((item) => (
            <CardClasses
               key={`${item.documentId}`}
               configComponents={[
                 !item.isActive ?
                    <ClassOptionDisabledText key={`${createReactKeyId('cl-codt')}`} text={lang.CLASS_ENDED}/> : null,
               ]}
               components={[
                 <ClassItem key={`${createReactKeyId('cl-ci')}`} item={item}/>,
               ]}
               buttonRow={[
                 item.isActive ? <BookingClassActionsRow key={`bcar-${item.documentId}`} item={item}/> : null,
               ]}
            />
         ))
       }
     </div>
  ) : classes?.length === 0 ?
     <NoContent {...{
       title: lang.NO_CLASSES_FOUND,
       description: lang.RETURN_LATER_DESCRIPTION,
       components: []
     }}/>
     : <Spin/>
}

export default ClassesList;
