import React, {useState} from 'react';
import {Button, Form, Modal} from "antd";
import {lang} from "../../../config/config";
import {SignUpFirstNameInput, SignUpLastNameInput} from "../../Firebase/Auth/SignUp";
import {arrayUnion} from "firebase/firestore";
import {firebaseAuthAPI} from "../../../features/firebase/auth/firebaseAuthAPI";


export const ModalFormChangeDisplayName = ({isOpen, setDisplayed}) => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    setIsLoading(true)
    form.validateFields().then(() => {
      const data = {
          displayName: `${form.getFieldValue('firstname')} ${form.getFieldValue('lastname')}`,
          searchParams: arrayUnion(form.getFieldValue('firstname').toLowerCase(), form.getFieldValue('lastname').toLowerCase())
      }

      firebaseAuthAPI.updateAuthProfile(data).then(() => {
        setIsLoading(false)
        setDisplayed()
      }).finally(() => {
        setIsLoading(false)
      })

    }).catch(() => {
      setIsLoading(false)
    })
  }

  return (
     <Modal
        title={`Ändra förnamn & efternamn`}
        centered
        visible={isOpen}
        okText={lang.APPLY}
        onCancel={setDisplayed}
        cancelText={lang.ABORT}
        footer={[
          <Button key="back" onClick={setDisplayed}>
            {lang.ABORT}
          </Button>,
          <Button key="submit" type="primary" loading={isLoading} onClick={onSubmit}>{lang.APPLY}</Button>,
        ]}
     >
       <Form
          layout={'vertical'}
          form={form}
       >
         <SignUpFirstNameInput/>
         <SignUpLastNameInput/>
       </Form>
     </Modal>
  )
};
