import React from "react";
import logo from "../assets/images/bw_logo.png";
import {QuickNavigationButton} from "../components/SharedComponents/Basic/ActionButton";

const PageWelcome = () => {

  return (
     <React.Fragment>
       <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', color: 'white', placeItems: 'center', alignItems: 'center'}}>
         <h1 style={{color: 'white', marginBottom: 30}}>Välkommen till</h1>
         <div style={{maxWidth: '25%', maxHeight: '25%', marginBottom: 30}}>
           <img style={{width: '100%', height: '100%'}} src={logo} alt="logo"/>
         </div>
         <p style={{color: 'white', marginBottom: 30, maxWidth: '25%'}}>Tryck på fortsätt för att komma till tillgängliga pass</p>

         <QuickNavigationButton text={'Fortsätt'} path={'/'}/>
       </div>
     </React.Fragment>
  )

}
export default PageWelcome;
