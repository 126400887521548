import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDocs,
  getFirestore,
  increment,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch
} from "firebase/firestore";
import {documentFrom, documentFromSnapListener} from "./firestoreClassesSlice";
import {setUserData} from "./firestoreCurrentUserData";
import {langResponses} from "../../../config/config";
import {createClassSequenceFromData} from "../../../util";
import {getCalendarDisplayInterval, getMillisInterval, organizeCalendar} from "./index";
import {eventDocumentFromSnapListener} from "./firestoreEventsSlice";

const COLLECTION_CLASSES = "classes"
const COLLECTION_USERS = "users"
const COLLECTION_EVENTS = "events"

async function addUserDocListener(uid, dispatch) {
  const db = getFirestore()
  return onSnapshot(doc(db, COLLECTION_USERS, uid),
     (doc) => {
       dispatch(setUserData(JSON.stringify(doc.data())))
     })
}

async function attachClassesSnapshotListener(dispatch) {

  try {

    const db = getFirestore()
    const millisInterval = getMillisInterval()
    const q = query(collection(db, COLLECTION_CLASSES), where("timeRange.classStart", ">=", millisInterval.dateMinMillis), where("timeRange.classStart", "<=", millisInterval.dateMaxMillis))

    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = assignDocumentIdToDoc(change.doc.data(), change.doc.id)
        dispatch(documentFromSnapListener({
          changeType: change.type, listTarget: "LIST", docData: doc
        }))
      });
    })

  } catch (e) {
    console.debug(e)
  }

}

async function attachActiveClassesSnapshotListener(dispatch) {

  try {


    const db = getFirestore()
    const q = query(collection(db, COLLECTION_CLASSES), where("isActive", "==", true))

    return onSnapshot(q, (snapshot) => {

      snapshot.docChanges().forEach((change) => {
        const doc = assignDocumentIdToDoc(change.doc.data(), change.doc.id)
        // console.log('changes to::', doc)
        // console.log('changes type::', change.type)
        dispatch(documentFrom({
          changeType: change.type, listTarget: "LIST_ALL_ACTIVE_CLASSES", docData: doc
        }))

      });
    })

  } catch (e) {
    console.debug(e)
  }

}

async function attachEventsSnapshotListener(dispatch) {

  try {

    const db = getFirestore()
    const q = query(collection(db, COLLECTION_EVENTS), where("isActive", "==", true))

    return onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const doc = assignDocumentIdToDoc(change.doc.data(), change.doc.id)
        dispatch(eventDocumentFromSnapListener({
          changeType: change.type, listTarget: "LIST_EVENTS", docData: doc
        }))
      });
    })

  } catch (e) {
    console.debug(e)
  }

}

async function createClass(data) {

  try {
    const db = getFirestore()
    await addDoc(collection(db, COLLECTION_CLASSES), data)

    return {type: 'FULFILLED', message: langResponses.CREATE_DOC_CLASS_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.CREATE_DOC_CLASS_FAILED}
  }
}

async function createClassSequence(data) {

  try {

    const db = getFirestore()
    const newDocumentsArr = createClassSequenceFromData(data.data, data.sequences)

    await newDocumentsArr.forEach((document) => {
      const docRef = doc(collection(db, COLLECTION_CLASSES))
      setDoc(docRef, document);
    })

    return {type: 'FULFILLED', message: langResponses.CREATE_SEQUENCE_DOCS_CLASS_SUCCESS}

  } catch (e) {
    return {type: 'REJECTED', message: langResponses.CREATE_SEQUENCE_DOCS_CLASS_FAILED}
  }

}

async function getClasses() {

  const db = getFirestore()
  const millisInterval = getMillisInterval()
  const q = query(collection(db, COLLECTION_CLASSES), where("timeRange.classStart", ">=", millisInterval.dateMinMillis), where("timeRange.classStart", "<=", millisInterval.dateMaxMillis))
  const querySnapshot = await getDocs(q);
  const arr = []

  await querySnapshot.forEach((doc) => {
    arr.push(assignDocumentIdToDoc(doc.data(), doc.id))
  })

  return arr
}

async function updateClass(data) {
  try {
    const db = getFirestore()
    await updateDoc(doc(db, COLLECTION_CLASSES, data.documentId), data.data);
    return {type: 'FULFILLED', message: langResponses.UPDATE_DOC_CLASS_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.UPDATE_DOC_CLASS_FAILED}
  }
}

async function updateUser(data) {
  try {
    const db = getFirestore()
    await updateDoc(doc(db, COLLECTION_USERS, data.uid), data.data);
    return {type: 'FULFILLED', message: langResponses.UPDATE_DOC_USER_SUCCESS}
  } catch (e) {
    console.log(e)
    return {type: 'REJECTED', message: langResponses.UPDATE_DOC_USER_FAILED}
  }
}

async function createUserData(data) {
  try {
    const db = getFirestore()
    await setDoc(doc(db, COLLECTION_USERS, data.uid), data.data, { merge: true });
    return {type: 'FULFILLED', message: langResponses.UPDATE_DOC_USER_SUCCESS}
  } catch (e) {
    console.log(e)
    return {type: 'REJECTED', message: langResponses.UPDATE_DOC_USER_FAILED}
  }
}

async function updateClassAttendance(data) {

  try {

    const db = getFirestore()
    const batch = writeBatch(db)
    const classDocument = doc(db, COLLECTION_CLASSES, data.documentId)

    await updateDoc(classDocument, data.data);

    await data.data.attendance.filter(x => !(x).checked).forEach((user) => {
      batch.update(doc(db, COLLECTION_USERS, user.uid), {
        strikes: increment(1),
      })
    })

    await batch.commit()

    return {type: 'FULFILLED', message: langResponses.UPDATE_DOC_CLASS_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.UPDATE_DOC_CLASS_FAILED}
  }
}

async function removeClass(data) {
  try {
    const db = getFirestore()
    await deleteDoc(doc(db, COLLECTION_CLASSES, data.documentRef.documentId));
    return {type: 'FULFILLED', message: langResponses.DELETE_DOC_CLASS_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.DELETE_DOC_CLASS_FAILED}
  }
}

async function addBooking(data) {

  try {
    const db = getFirestore()
    await updateDoc(doc(db, COLLECTION_CLASSES, data.documentId), {bookedUsers: arrayUnion(data.data)});

    return {type: 'FULFILLED', message: langResponses.ADD_BOOKING_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.ADD_BOOKING_FAILED}
  }
}

async function addGuestBookings(data) {

  try {
    const db = getFirestore()

    if (data.type === "classBookings") {
      // const targetDocument = doc(db, COLLECTION_CLASSES, data.documentId)
      await updateDoc(doc(db, COLLECTION_CLASSES, data.documentId), {bookedUsers: arrayUnion(...data.data)}, {merge: true});

    } else if (data.type === "eventBookings") {
      // const targetDocument = doc(db, COLLECTION_EVENTS, data.documentId)
      await updateDoc(doc(db, COLLECTION_EVENTS, data.documentId), {bookedUsers: arrayUnion(...data.data)}, {merge: true});
    }

    return {type: 'FULFILLED', message: langResponses.ADD_BOOKING_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: e.message}
    // return {type: 'REJECTED', message: langResponses.ADD_BOOKING_FAILED}
  }
}


async function removeBooking(data) {

  try {
    const db = getFirestore()
    const booking = {...data.data}
    const documentRef = data.documentRef

    const classDocument = doc(db, COLLECTION_CLASSES, documentRef.documentId)
    await updateDoc(classDocument, {bookedUsers: arrayRemove(booking)});

    if (documentRef.reservedUsers[0]) {
      const reservedUser = documentRef.reservedUsers[0]
      await updateDoc(classDocument, {bookedUsers: arrayUnion(reservedUser), reservedUsers: arrayRemove(reservedUser)});
    }

    return {type: 'FULFILLED', message: langResponses.REMOVE_BOOKING_SUCCESS}

  } catch (e) {
    return {type: 'REJECTED', message: langResponses.REMOVE_BOOKING_FAILED}
  }
}

async function removeBookingReserved(data) {

  try {
    const db = getFirestore()
    const booking = {...data.data}
    const documentRef = data.documentRef

    const classDocument = doc(db, COLLECTION_CLASSES, documentRef.documentId)
    await updateDoc(classDocument, {reservedUsers: arrayRemove(booking)});

    return {type: 'FULFILLED', message: langResponses.REMOVE_BOOKING_SUCCESS}

  } catch (e) {
    return {type: 'REJECTED', message: langResponses.REMOVE_BOOKING_FAILED}
  }
}

async function removeBookingAdmin(data) {

  try {

    const db = getFirestore()
    const booking = {...data.data}
    const documentRef = data.documentRef
    const reservedUser = data.documentRef.reservedUsers[0]

    const classDocument = doc(db, COLLECTION_CLASSES, documentRef.documentId)

    await updateDoc(classDocument, {bookedUsers: arrayRemove(booking)});

    if (reservedUser) {
      await updateDoc(classDocument, {bookedUsers: arrayUnion(reservedUser), reservedUsers: arrayRemove(reservedUser)});
    }

    return {type: 'FULFILLED', message: langResponses.REMOVE_BOOKING_ADMIN_SUCCESS}

  } catch (e) {
    return {type: 'REJECTED', message: langResponses.REMOVE_BOOKING_ADMIN_FAILED}
  }
}

async function addReserveBooking(data) {

  try {

    const db = getFirestore()
    const batch = writeBatch(db)

    const booking = {...data.data}
    const documentRef = data.documentRef

    // const userDocument = doc(db, COLLECTION_USERS, booking.uid)
    const classDocument = doc(db, COLLECTION_CLASSES, documentRef.documentId)

    // batch.update(userDocument, {reservedClasses: arrayUnion(documentRef.documentId)});
    batch.update(classDocument, {reservedUsers: arrayUnion(booking)});

    await batch.commit()

    return {type: 'FULFILLED', message: langResponses.ADD_BOOKING_SUCCESS}

  } catch (e) {
    return {type: 'REJECTED', message: langResponses.ADD_BOOKING_SUCCESS}
  }
}

async function removeReservedBookingAdmin(data) {

  try {
    const db = getFirestore()
    const batch = writeBatch(db)
    const booking = {...data.data}
    const documentRef = data.documentRef

    // const userDocument = doc(db, COLLECTION_USERS, booking.uid)
    const classDocument = doc(db, COLLECTION_CLASSES, documentRef.documentId)

    batch.update(classDocument, {reservedUsers: arrayRemove(booking)})
    // batch.update(userDocument, {reservedClasses: arrayRemove(documentRef.documentId)})

    await batch.commit()

    return {type: 'FULFILLED', message: langResponses.REMOVE_RESERVE_BOOKING_ADMIN_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.REMOVE_RESERVE_BOOKING_ADMIN_FAILED}
  }
}

async function getInstructors() {

  try {

    const db = getFirestore()
    const ref = query(collection(db, COLLECTION_USERS), where("roles", "array-contains", "INSTRUCTOR"))
    const querySnapshot = await getDocs(ref);

    const arr = []

    await querySnapshot.forEach((doc) => {
      arr.push(assignDocumentIdToDoc(doc.data(), doc.id))
    })

    return {type: 'FULFILLED', message: 'Instructors get success!', data: arr}
    // return arr

  } catch (e) {
    return {type: 'REJECTED', message: `${e}`}
  }
}


async function getUsersBySearchParam(data) {

  const db = getFirestore()
  const ref = query(collection(db, COLLECTION_USERS), where("searchParams", "array-contains-any", data.searchParams))
  const querySnapshot = await getDocs(ref);

  const arr = []

  await querySnapshot.forEach((doc) => {
    arr.push(assignDocumentIdToDoc(doc.data(), doc.id))
  })

  return {type: 'FULFILLED', message: 'Search fetch success!', data: arr}

}

async function getUsers() {

  try {

    const db = getFirestore()
    const ref = query(collection(db, COLLECTION_USERS), where("roles", "array-contains", "USERS"))
    const querySnapshot = await getDocs(ref);

    const arr = []

    await querySnapshot.forEach((doc) => {
      arr.push(assignDocumentIdToDoc(doc.data(), doc.id))
    })

    return {type: 'FULFILLED', message: 'Instructors get success!', data: arr}


  } catch (e) {
    return {type: 'REJECTED', message: `${e}`}
  }

}

function assignDocumentIdToDoc(documentData, id) {
  Object.assign(documentData, {documentId: id})
  return documentData
}

async function getCalender() {

  try {

    const db = getFirestore()
    const millisInterval = getCalendarDisplayInterval()
    const q = query(collection(db, COLLECTION_CLASSES), where("timeRange.classStart", ">=", millisInterval.dateMinMillis), where("timeRange.classStart", "<=", millisInterval.dateMaxMillis))
    const querySnapshot = await getDocs(q);
    const arr = []

    await querySnapshot.forEach((doc) => {
      arr.push(assignDocumentIdToDoc(doc.data(), doc.id))
    })

    const result = organizeCalendar(arr)

    return {type: 'FULFILLED', message: 'Calendar get success!', data: result}

  } catch (e) {
    return {type: 'REJECTED', message: `${e}`}
  }

}

async function getEvents() {

  try {

    const db = getFirestore()
    const q = query(collection(db, COLLECTION_EVENTS), where("isActive", "==", true))
    const querySnapshot = await getDocs(q);
    const arr = []

    await querySnapshot.forEach((doc) => {
      arr.push(assignDocumentIdToDoc(doc.data(), doc.id))
    })

    return {type: 'FULFILLED', message: 'Events get success!', data: arr}

  } catch (e) {
    return {type: 'REJECTED', message: `${e}`}
  }

}

async function createEvent(data) {

  try {
    const db = getFirestore()
    await addDoc(collection(db, COLLECTION_EVENTS), data)
    return {type: 'FULFILLED', message: langResponses.CREATE_DOC_EVENT_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: e.message}
  }
}

async function removeEvent(data) {

  try {
    const db = getFirestore()
    await deleteDoc(doc(db, COLLECTION_EVENTS, data.documentRef.documentId));
    return {type: 'FULFILLED', message: langResponses.DELETE_DOC_CLASS_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.DELETE_DOC_CLASS_FAILED}
  }
}

async function updateEvent(data) {

  try {
    const db = getFirestore()
    await updateDoc(doc(db, COLLECTION_EVENTS, data.documentId), data.data);
    return {type: 'FULFILLED', message: langResponses.UPDATE_DOC_EVENT_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.UPDATE_DOC_EVENT_FAILED}
  }

}

async function addEventBooking(data) {

  try {
    const db = getFirestore()
    await updateDoc(doc(db, COLLECTION_EVENTS, data.documentId), {bookedUsers: arrayUnion(data.data)});

    return {type: 'FULFILLED', message: langResponses.ADD_BOOKING_SUCCESS}
  } catch (e) {
    return {type: 'REJECTED', message: langResponses.ADD_BOOKING_FAILED}
  }
}

async function removeEventBooking(data) {

  try {
    const db = getFirestore()
    const batch = writeBatch(db)
    const booking = {...data.data}
    const documentRef = data.documentRef

    const eventDocument = doc(db, COLLECTION_EVENTS, documentRef.documentId)
    batch.update(eventDocument, {bookedUsers: arrayRemove(booking)});

    await batch.commit()

    return {type: 'FULFILLED', message: langResponses.REMOVE_BOOKING_EVENT_ADMIN_SUCCESS}

  } catch (e) {
    return {type: 'REJECTED', message: langResponses.REMOVE_BOOKING_EVENT_ADMIN_FAILED}
  }
}

export const firestoreAPI = {
  addBooking,
  addGuestBookings,
  addEventBooking,
  addReserveBooking,
  addUserDocListener,
  attachActiveClassesSnapshotListener,
  attachClassesSnapshotListener,
  attachEventsSnapshotListener,
  createUserData,
  createClass,
  createClassSequence,
  createEvent,
  getCalender,
  getClasses,
  getInstructors,
  getUsers,
  getEvents,
  getUsersBySearchParam,
  removeBooking,
  removeBookingAdmin,
  removeEvent,
  removeBookingReserved,
  removeClass,
  removeEventBooking,
  removeReservedBookingAdmin,
  updateClass,
  updateClassAttendance,
  updateEvent,
  updateUser,
}
