import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {calendarLoading, getCalender, listCalendar} from "../../features/firebase/firestore/firestoreCalendarSlice";
import {eventsLoading, getEventDocuments, listOfEvents} from "../../features/firebase/firestore/firestoreEventsSlice";
import {lang} from "../../config/config";
import {Spin} from "antd";
import './Calendar.css'
import {CalendarEventItems} from "./CalendarEventItems";
import {CalenderClassItems} from "./CalenderClassItems";

const PagePublicCalendar = () => {

  const dispatch = useDispatch()
  const calendar = useSelector(listCalendar)
  const events = useSelector(listOfEvents)
  const isLoadingCalendar = useSelector(calendarLoading)
  const isLoadingEvents = useSelector(eventsLoading)

  useEffect(() => {
    dispatch(getCalender())
    dispatch(getEventDocuments())
  }, [dispatch])

  return calendar && !isLoadingCalendar && !isLoadingEvents ? (
     <div className={'Calendar-public-container'}>
       {events?.length !== 0 ?
          <React.Fragment>
            <div className={'Calendar-public-container Calender-header'}>
              <h2>{lang.EVENT}</h2>
            </div>
            <CalendarEventItems arr={events}/>
          </React.Fragment>
          : null
       }

       <div className={'Calendar-public-container Calender-header'}>
         <h2>{lang.CLASS}</h2>
       </div>
       <CalenderClassItems arr={calendar}/>

     </div>
  ) : <Spin/>
}
export default PagePublicCalendar;
