import './App.css';
import 'antd/dist/antd.css';
import Navigation from "./components/Navigation/Navigation";
import Header from "./components/Header/Header";
import {BrowserRouter as Router} from "react-router-dom";
import {RouterContent} from "./router/RouterContent";
import './components/Icons/Icons.css'
import React, {useContext} from "react";
import FirebaseAuthContext from "./components/Firebase/Auth/FirebaseAuthContext";
import {Spin} from "antd";
import {Provider, useDispatch} from "react-redux";
import {store} from "./app/store";
import FirebaseModuleProvider, {FirebaseAppContext} from "./components/Firebase/FirebaseModuleProvider";
import {BASE_URL} from "./config/config";

const App = () => {
  return (
     <Provider store={store}>
       <FirebaseModuleProvider>
         <Content/>
       </FirebaseModuleProvider>
     </Provider>
  )
}

const Content = () => {

  const dispatch = useDispatch()
  const firebase = useContext(FirebaseAppContext)

  return firebase.auth ? (
     <div className="App">
       <FirebaseAuthContext {...{dispatch}}>
         <Router basename={BASE_URL}>
           <Header/>
           <Navigation/>
           <RouterContent/>
         </Router>
         {/*<EmulatorDataUploader/>*/}
       </FirebaseAuthContext>
     </div>

  ) : <Spin/>

}

export default App;
