import React, {useState} from 'react';
import {Button, Form, Input} from 'antd';
import {lang} from "../../../config/config";
import {firebaseAuthAPI} from "../../../features/firebase/auth/firebaseAuthAPI";


const PasswordValidationForm = () => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)

  const onFinishFailed = () => {
  }

  const onFinish = () => {
    setIsLoading(true)
    firebaseAuthAPI.updateAuthPassword(form.getFieldValue('password')).then(() => {
      setIsLoading(false)
      form.resetFields()
    })
  }


  return (
     <Form
        name={'form_profile_password'}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
     >
       <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Vänligen ange ett lösenord!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                const re = /^[\w.-]+$/;

                if (getFieldValue('password').trim().length <= 6){
                  return Promise.reject(new Error('Minst 6 bokstäver och/eller siffror krävs.'));
                }
                if (!re.test(value)){
                  return Promise.reject(new Error('Endast bokstäver och siffror tillåtna.'));
                }
                return Promise.resolve();

              },
            }),
          ]}
          hasFeedback
       >
         <Input.Password
            type={"number" || "text"}
            minLength={6}
            placeholder={lang.PASSWORD}
         />
       </Form.Item>
       <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: lang.PASSWORD_CONFIRM,
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Lösenorden stämmer inte överrens'));
              },
            }),
          ]}
       >
         <Input.Password placeholder={lang.PASSWORD_CONFIRM}/>
       </Form.Item>
       <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
         <Button type={"primary"} loading={isLoading} htmlType={'submit'}>{lang.PASSWORD_CHANGE}</Button>
       </div>

     </Form>
  )
};

export default PasswordValidationForm;
