import React from 'react';
import logo from "../../assets/images/bw_logo.png";
import {AuthSignOutButton} from "../Firebase/Auth";

const Header = () => {
  return (
     <header className="App-header">
       <img src={logo} className="App-logo" alt="logo"/>
       <AuthSignOutButton/>
     </header>
  )
};

export default Header;
