import React from "react";
import EventForm from "../components/Events/EventForm";
import {useParams} from "react-router-dom";

const PageAdminEventEdit = () => {
  const params = useParams()
  return (
     <React.Fragment>
       <EventForm eventId={params.eventId} edit={true}/>
     </React.Fragment>
  )
}
export default PageAdminEventEdit;
