import React, {createContext, useEffect, useState} from "react";
import {initializeApp} from "firebase/app";
import {connectAuthEmulator, getAuth} from "firebase/auth";
import {connectFirestoreEmulator, getFirestore} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getFunctions} from "firebase/functions";

export const FirebaseAppContext = createContext({})

const FirebaseModuleProvider = (props) => {

  const [appModules, setAppModules] = useState({})

  useEffect(() => {

    const config = {
      apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
    }

    const firebaseApp = initializeApp(config)
    const firebaseAuth = getAuth(firebaseApp);
    const firebaseStorage = getStorage(firebaseApp);
    const firestoreDatabase = getFirestore(firebaseApp);
    const firebaseFunctions = getFunctions(firebaseApp);
    firebaseAuth.defaultLanguageCode = "sv-SE"


    if (process.env.NODE_ENV === 'development') {
      connectFirestoreEmulator(firestoreDatabase, 'localhost', 8080);
      connectAuthEmulator(firebaseAuth, "http://localhost:9099", {disableWarnings: false});
    }

    console.log('ENVIROMENT IS:', process.env.NODE_ENV)

    setAppModules({
         app: firebaseApp,
         auth: firebaseAuth,
         storage: firebaseStorage,
         firestore: firestoreDatabase,
         functions: firebaseFunctions
       }
    )

  }, [])

  return (
     <FirebaseAppContext.Provider value={appModules}>
       {props.children}
       {/*{process.env.NODE_ENV === 'development' ?*/}
       {/*   <MAdminPanelFirebaseAuth usingEmulators={(process.env.NODE_ENV === 'development')}/> : null}*/}
     </FirebaseAppContext.Provider>
  )

}

export default FirebaseModuleProvider
