import React, { useState } from 'react'
import { CardForm } from "../SharedComponents/View";
import { lang } from "../../config/config";
import { TeamOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getUsersBySearchParam, listOfSearches, updateUser } from "../../features/firebase/firestore/firestoreUsersSlice";
import Search from "antd/es/input/Search";
import { Button, Checkbox, Divider } from "antd";
import './Admin.css';
import {
  assignUserRoles,
  createReactKeyId,
  displayHttpResponseMessage,
  getDetailedDateTime,
  isEpochMillisInFuture,
  searchParamAsArray,
  setMillisOneWeekBackFromNow
} from "../../util";

const AdminSearch = () => {

  const dispatch = useDispatch()
  const listSearches = useSelector(listOfSearches)
  const [loadingSearches, setIsLoadingSearches] = useState(false);

  const onSearch = (value) => {
    setIsLoadingSearches(true)
    const searchParam = searchParamAsArray(value)
    dispatch(getUsersBySearchParam({ searchParams: searchParam })).then(() => {
      setTimeout(() => {
        setIsLoadingSearches(false)
      }, 1000)

    })
  }

  return (

    <div className={'Admin-search-container'}>

      <Search
        placeholder={lang.SEARCH_BY_NAME}
        allowClear
        loading={loadingSearches}
        style={{ padding: 15, fontSize: 'calc(10px + 0.2vmin)' }}
        size="large"
        onSearch={onSearch}
      />

      {
        listSearches?.length !== 0 ?
          <div className={'Admin-search-container search-list-container'}>
            {
              listSearches ?
                <CardForm
                  key={`${createReactKeyId('card-drop-down')}`}
                  settings={{
                    title: lang.SEARCH_RESULTS,
                    icon: <TeamOutlined />
                  }}
                  components={[<UserItemCompact key={`user-item-compact`} list={listSearches} />]}
                /> : null
            }
          </div>
          : null
      }


    </div>
  )
}

const UserItemCompact = ({ list }) => {

  return (
    <div className={'User-item-compact-container'}>
      {list?.map((user) => (

        <div key={`user-item-compact-${user.uid}`} className={'User-item-compact-container user'}>

          <b>{`${user.displayName}`}</b>
          <i>{`${user.email}`}</i>
          <i>{`${user.phoneNumber ? user.phoneNumber : 'Telnr saknas'}`}</i>

          {user.strikes !== 0 ? <i>{`${user.strikes} pass missade`}</i> : null}
          {isEpochMillisInFuture(user.disabledUntil) ?
            <i>{`Blockerad t.om. ${getDetailedDateTime(user.disabledUntil)}`}</i> : null}

          <UserInfo user={user} />
          <Divider />
        </div>

      ))}
    </div>

  )
}

const UserInfo = ({ user }) => {

  const dispatch = useDispatch()
  const [adminChecked, setAdminChecked] = useState(user.roles.includes('ADMIN'))
  const [instructorChecked, setInstructorChecked] = useState(user.roles.includes('INSTRUCTOR'))
  const [instructorEnabled, setInstructorEnabled] = useState(false)
  const [isUserDisabled, setIsUserDisabled] = useState(isEpochMillisInFuture(user.disabledUntil))

  const onInstructorChange = () => {
    setInstructorChecked(!instructorChecked)
  }
  const onAdminChange = () => {
    setAdminChecked(!adminChecked)
    setInstructorChecked(!adminChecked)
    setInstructorEnabled(!adminChecked)
  }

  const onUnblockUser = () => {

    dispatch(updateUser({
      uid: user.uid,
      data: {
        strikes: 0,
        disabledUntil: setMillisOneWeekBackFromNow()
      }

    })).then((response) => {
      displayHttpResponseMessage(response.payload) //CHECK RESPONSE.payload.type => set false if uploaded else dont change
      setIsUserDisabled(false)
    })

  };

  const onSaveClick = () => {
    dispatch(updateUser({
      uid: user.uid,
      data: {
        roles: assignUserRoles(adminChecked, instructorChecked)
      }
    })).then((response) => {
      displayHttpResponseMessage(response.payload)
    })
  }

  return (
    <div key={`user-info-container-${user.uid}`} className={'User-info-container'}>

      <div key={`user-info-checked-${user.uid}`} className={'User-info-container checkbox-row'}>
        <Checkbox checked={adminChecked} onChange={onAdminChange}>{lang.ADMIN}</Checkbox>
        <Checkbox disabled={instructorEnabled} checked={instructorChecked} onChange={onInstructorChange}>{lang.INSTRUCTOR}</Checkbox>
      </div>

      <div className={'User-info-container button-row'}>

        {isUserDisabled ?
          <Button
            key={`button-unblock-${user.uid}`}
            danger
            onClick={() => onUnblockUser()}
          >
            {lang.UNBLOCK}
          </Button>
          : null}
        <Button
          key={`button-save-${user.uid}`}
          onClick={() => onSaveClick()}>
          {lang.SAVE}
        </Button>
      </div>

    </div>
  )

}
export default AdminSearch;
