import React from "react";
import MyClasses from "../components/Classes/MyClasses";

const PageMyClasses = () => {

  return (
     <React.Fragment>
       <MyClasses/>
     </React.Fragment>
  )

}
export default PageMyClasses;
