import React, {useEffect, useState} from "react";
import {Divider} from "antd";
import {getTimeRangeLongTitleCased, getTimeRangeString,} from "../../../util";
import {lang} from "../../../config/config";
import {IC_CALENDER_COMPACT, IC_CARD_COMPACT, IC_CLOCK_COMPACT} from "../../Icons/Icons";
import {useSelector} from "react-redux";
import {userData} from "../../../features/firebase/firestore/firestoreCurrentUserData";
import {listOfEvents} from "../../../features/firebase/firestore/firestoreEventsSlice";

const ProfileBookedEvents = () => {

  const user = useSelector(userData)
  const events = useSelector(listOfEvents)
  const [bookedEvents, setBookedEvents] = useState([])

  useEffect(() => {
    const arr = []
    events.forEach((classItem) => {
      if (classItem.bookedUsers?.find(x => x.uid === user.uid)) {
        arr.push(classItem)
      }
    })
    setBookedEvents(arr)
  }, [events])

  return bookedEvents?.length !== 0 ?
     <div style={{display: 'flex', flexDirection: 'column', maxHeight: 400, gap: '1rem', overflowY: 'scroll'}}>
       {bookedEvents?.map((item) => (
          <EventItemCompact key={`event-item-compact-${item.documentId}`} item={item}/>
       ))}
     </div>
     : <i>{lang.NO_BOOKED_EVENTS_USER}</i>
}

const EventItemCompact = ({item}) => {

  const dated = `${getTimeRangeLongTitleCased(item.timeRange.eventStart)}`
  const timeStartEnd = `${getTimeRangeString(item.timeRange.eventStart, item.timeRange.eventEnd)}`
  const title = `${item.name}`
  const cost = `${item.cost}`

  return (
     <div style={{
       display: 'flex',
       flexDirection: 'column',
       flexWrap: 'noWrap',
       minHeight: 150,
       gap: '0.3rem',
       margin: 15
     }}>

       <div><b>{title}</b></div>
       <div>{IC_CALENDER_COMPACT}{dated}</div>
       <div>{IC_CLOCK_COMPACT}{timeStartEnd}</div>
       <div>{IC_CARD_COMPACT}{cost} kr</div>

       <i>Event avbokas genom kontakt med anläggning</i>

       <Divider/>

     </div>
  )
}

export default ProfileBookedEvents;
