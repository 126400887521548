import React from "react";
import AdminActiveEvents from "../components/Admin/AdminActiveEvents";

const PageAdminActiveEvents = () => {

  return (
     <React.Fragment>
       <AdminActiveEvents/>
     </React.Fragment>
  )

}
export default PageAdminActiveEvents;
