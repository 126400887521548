import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {listOfAllActiveClasses, removeClass} from "../../features/firebase/firestore/firestoreClassesSlice";
import {CardClasses, NoContent} from "../SharedComponents/View";
import {ClassItem, ConfigRowAdmin} from "../Classes";
import {lang} from "../../config/config";
import {message, Spin, Tooltip} from "antd";
import {RedoOutlined} from "@ant-design/icons";

const AdminActiveClasses = () => {

  const dispatch = useDispatch()
  const classes = useSelector(listOfAllActiveClasses)
  // const history = useHistory();

  const onRemoveClick = (item) => {
    return dispatch(removeClass({
      documentRef: item
    })).then(() => {
      message.info('Pass borttaget')
    })
  }

  return classes?.length !== 0 ? (
     <div className={'Class-list-container'}>
       {
         classes.map((item) => (
            <CardClasses
               key={`card-active-classes-${item.documentId}`}
               configComponents={[
                 <ConfigRowAdmin
                    key={`config-row-admin-${item.documentId}`}
                    onClickDelete={() => onRemoveClick(item)}
                    pathEdit={'/admin/all-classes'} item={item}/>,
               ]}
               components={[
                 item.isActive ? <ClassItem key={`class-item-${item.documentId}`} item={item}/> : null,
               ]}
               buttonRow={[
                 item.sequenceId ? <Tooltip key={`button-row-sequence-${item.documentId}`} color={'#2d2d2d'} title={
                   <div>
                     <b>{item.sequenceId}</b>
                   </div>
                 }>
                   <RedoOutlined/>
                 </Tooltip> : null,
               ]}
            />
         ))
       }
     </div>
  ) : classes?.length === 0 ?
     <NoContent {...{
       title: lang.NO_CLASSES_FOUND,
       description: 'Inga aktiva pass hittade!',
       components: [
         // <QuickNavigationButton path={'/create-class'} text={lang.CREATE_CLASS}/>,
       ]
     }}/>
     : <Spin/>
}


export default AdminActiveClasses;
