import {Card, Divider} from "antd";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";

export const ModalCardContainer = ({settings, components}) => {

  return (
     <Card
        title={settings.title}
        size={'default'}
        bordered={false}
        style={{width: '100%', marginBottom: 5}}>
       {components}
     </Card>
  )
}

export const CardForm = ({settings, components, buttonRow}) => {

  const history = useHistory()

  const navigateBack = () => {
    history.goBack()
  }

  return (
     <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 0 2px 0 rgba(65, 65, 65, 0.2)',
          marginBottom: 15,
          borderRadius: 5,
          background: 'rgb(255,255,255)',
          padding: 15,
          width: '100%',
          maxWidth: 900,
        }}
     >
       <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 'bold',
            alignItems: 'center'
          }}
       >
         {settings.icon ?
            <div onClick={settings.navigateBack ? navigateBack : null}
                 style={{cursor: settings.navigateBack ? 'pointer' : null, padding: 5}}>
              {settings.icon}
            </div>
            : null
         }
         {settings.title ?
            <div>
              {settings.title.toUpperCase()}
            </div>
            : null
         }


       </div>

       {settings.title ? <Divider/> : null}

       <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: 5,
            // background: 'rgba(0,0,0, 0.1)',
            padding: 15
          }}
       >
         {components}
       </div>


       {buttonRow ?
          <div
             style={{
               display: 'flex',
               flexDirection: 'row-reverse',
               alignItems: 'flex-end',
               width: '100%',
               marginBottom: 5,
               gap: '0.5rem',
               // background: 'rgba(0,0,0, 0.1)',
               padding: 15
             }}
          >
            {buttonRow}
          </div>
          : null
       }


     </div>
  )
}

export const CardClasses = ({configComponents, components, buttonRow}) => {

  return (
     <React.Fragment>
       <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 0 2px 0 rgba(65, 65, 65, 0.2)',
            backgroundColor: 'rgb(255, 255, 255)',
            borderRadius: 5,
            position: 'relative'
          }}
       >
         <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '0.2rem',
            }}
         >

           <div style={{
             display: 'grid',
             flexWrap: 'wrap',
             gridTemplateColumns: 'repeat(auto-fit, minmax(110px, 1fr)',
             gap: '0.2rem',
             position: 'relative',
             padding: '2rem',
           }}
           >
             {components}
           </div>

           <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}>

             <div style={{
               display: 'grid',
               flexWrap: 'wrap',
               gridTemplateColumns: 'repeat(auto-fit, minmax(110px, 1fr)',
               margin: '1rem',
               gap: '0.2rem',
             }}
             >
               {configComponents}
             </div>

             <Divider style={{marginBottom: 0, marginTop: 0}} dashed/>

             <div
                style={{
                  display: 'flex',
                  padding: '1rem',
                  width: '100%',
                  justifyContent: 'flex-end',
                  flexWrap: 'wrap',
                  justifyItems: 'center',
                }}>
               {buttonRow}

             </div>

           </div>

         </div>
       </div>

     </React.Fragment>
  )
}

export const CompactRow = ({components}) => {
  return (
     <div style={{display: 'flex', flexDirection: 'row', gap: '1rem'}}>
       {components}
     </div>
  )
}


export const CardDropdown = ({settings, components, componentsDrop, expanded}) => {

  const [dropdownVisible, setDropdownVisible] = useState(expanded ? expanded : false)

  const triggerDropdown = () => {
    setDropdownVisible(!dropdownVisible)
  }

  return (
     <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          boxShadow: '0 0 2px 0 rgba(65, 65, 65, 0.2)',
          marginBottom: 15,
          background: 'rgb(255,255,255)',
          padding: 15,
          borderRadius: 5,
        }}
     >
       <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
            fontSize: '1rem',
            fontWeight: 'bold',
            alignItems: 'center',
            padding: '0.2rem'
          }}
       >
         {settings.icon ?
            <div style={{padding: 5}}>
              {settings.icon}
            </div>
            : null
         }
         {settings.title ?
            <div>
              {settings.title.toUpperCase()}
            </div>
            : null
         }
         <div style={{marginLeft: 'auto'}}>

           <button type={"text"}
                   style={{
                     background: 'none',
                     marginTop: 10,
                     marginBottom: 10,
                     borderRadius: 12,
                     border: 'none',
                     textAlign: 'center',
                     fontSize: 'calc(10px + 0.2vmin)'
                   }}
                   onClick={() => triggerDropdown()}>{!dropdownVisible ? 'VISA' : 'DÖLJ'}</button>

         </div>


       </div>

       {settings.title ? <Divider/> : null}

       <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: 5,
          }}
       >
         {components}
       </div>

       <div style={{
         display: dropdownVisible ? 'flex' : 'none',
         flexDirection: 'column',
         padding: '15px 5px 15px 5px'
       }}>
         {componentsDrop}
       </div>
     </div>
  )
}


export const NoContent = (props) => {

  return (
     <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: 15,
          textAlign: 'center',
          color: 'white',
          gap: '1rem',
          justifyContent: 'center',
          alignItems: 'center'
        }}
     >
       <b style={{fontSize: 14}}>{props.title}</b>
       <i style={{fontSize: 12}}>{props.description}</i>
       {props.components}
     </div>
  )
}

// backgroundImage: `linear-gradient(120deg, ${categoryColor}80 0%, ${categoryColor}99 100%`,
