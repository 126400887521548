import React from "react";
import ClassForm from "../components/Classes/ClassForm";
import {useParams} from "react-router-dom";

const PageEditClass = () => {

  const params = useParams()

  return (
     <React.Fragment>
       <ClassForm classId={params.classId} edit={true}/>
     </React.Fragment>
  )

}
export default PageEditClass;
