import React, {useState} from 'react';
import {CardDropdown, CardForm} from "../SharedComponents/View";
import ProfileSettings from "./ProfileSettings";
import {
  EditOutlined,
  IdcardOutlined,
  MailOutlined,
  PhoneOutlined,
  ScheduleOutlined,
  SettingOutlined,
  UserOutlined
} from "@ant-design/icons";
import {lang} from "../../config/config";
import ProfileBookedClasses from "./Bookings/ProfileBookedClasses";
import {useSelector} from "react-redux";
import {userData} from "../../features/firebase/firestore/firestoreCurrentUserData";
import ProfileReservedClasses from "./Bookings/ProfileReservedClasses";
import {createReactKeyId, getTimeRangeLongTitleCased, isEpochMillisInFuture} from "../../util";
import ProfileBookedEvents from "./Bookings/ProfileBookedEvents";
import {Button} from "antd";
import {ModalFormChangeDisplayName} from "./Settings/ModalFormChangeDisplayName";


const Profile = () => {

  const user = useSelector(userData)

  const [displayUserNameModal, setDisplayUserNameModal] = useState(false)

  const displayChangeUserNameModal = () => {
      setDisplayUserNameModal(!displayUserNameModal)
  }

  return user ? (
     <div style={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 600}}>

       <CardForm
          key={`${createReactKeyId('profile-card-form')}`}
          settings={{
            title: lang.GENERAL,
            icon: <IdcardOutlined/>
          }}
          components={[
            <div><UserOutlined style={{marginRight: '0.25rem'}}/> {user.displayName} {checkValidDisplayName(user.displayName, () => displayChangeUserNameModal())}</div>,
            <div><MailOutlined style={{marginRight: '0.25rem'}}/> {user.email}</div>,
            <div><PhoneOutlined style={{marginRight: '0.5rem'}}/>
              {user.phoneNumber?.length ? `+46 ${user.phoneNumber}` :
                 <b style={{fontWeight: 400}}>Inget telefonnummer tillagt</b>
              }</div>,
            isEpochMillisInFuture(user.disabledUntil) ? <i key={'disabled-info'} style={{
              marginTop: 15,
              color: 'rgb(156,42,42)'
            }}>{`Du har missat närvara på 3 bokningar. Du är blockerad från bokning till ${getTimeRangeLongTitleCased(user.disabledUntil)}, 
            vänligen kontakta en administratör hos oss för att häva blockeringen tidigare!`}</i> : null,
          ]}
          buttonRow={[]}
       />

       <CardForm settings={{title: lang.BOOKED_CLASSES, icon: <ScheduleOutlined/>}} components={[
         <ProfileBookedClasses key={'profile-booked-classes'}/>,
       ]}/>

       <CardForm settings={{title: lang.RESERVED_CLASSES, icon: <ScheduleOutlined/>}} components={[
         <ProfileReservedClasses key={'profile-reserved-classes'}/>,
       ]}/>

       <CardForm settings={{title: lang.BOOKED_EVENTS, icon: <ScheduleOutlined/>}} components={[
         <ProfileBookedEvents key={'profile-booked-events'}/>,
       ]}/>

       <CardDropdown
          key={`${createReactKeyId('profile-card-drop-down')}`}
          settings={{
            title: lang.SETTINGS,
            icon: <SettingOutlined/>
          }}
          components={[]}
          componentsDrop={[
            <ProfileSettings key={'profile-settings-in-drop'}/>,
          ]}
       />


       <ModalFormChangeDisplayName
          isOpen={displayUserNameModal}
          setDisplayed={displayChangeUserNameModal}

       />


     </div>
  ) : null
};

const checkValidDisplayName = (displayName, onClick) => displayName === "Ej angivet" ? <Button type="primary" icon={<EditOutlined />} onClick={onClick} size={'small'} style={{width: 35}}/> :null


export default Profile;
