import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  DATA: null,
};

export const firestoreCurrentUserData = createSlice({
  name: 'firestoreCurrentUserData',
  initialState,
  reducers: {
    setUserData: (state, action) => {
      try {
        state.DATA = JSON.parse(action.payload)
      } catch (e) {
        state.DATA = null
      }
    },
  },

});

export const {
  setUserData,
} = firestoreCurrentUserData.actions;
export const userData = (state) => state.currentUserData.DATA;

export default firestoreCurrentUserData.reducer;
