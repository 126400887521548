import React from "react";
import AdminSearch from "../components/Admin/AdminSearch";

const PageAdminSearch = () => {
  return (
     <React.Fragment>
       <AdminSearch/>
     </React.Fragment>
  )
}
export default PageAdminSearch;
