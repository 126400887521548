import React from "react";
import '../SharedComponents.css'

export const TextFieldCharCounter = ({charCount, max}) => {


  function charMaxCount() {
    return charCount >= max
  }

  return (
     <React.Fragment>
       <div className={'Text-field-char-counter-container'}>
         <div>{`${charCount}`}</div>
         {'/'}
         <div style={{
           color: charMaxCount() ? '#3f3f3f' : '#2d2d2d',
           fontWeight: 'bold'
         }}>{`${max}`}</div>

       </div>

     </React.Fragment>
  )
}
