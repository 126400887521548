import React, {useContext} from "react";
import {AuthContext} from "./FirebaseAuthContext";
import {firebaseAuthAPI} from "../../../features/firebase/auth/firebaseAuthAPI";
import {Button, Form, Popconfirm, Select} from "antd";
import {base_logo, CLASSES_SLOTS_ARR, lang} from "../../../config/config";
import {GoogleOutlined} from "@ant-design/icons";
import './Auth.css'
import {useHistory} from "react-router-dom";
import {ContactButton} from "../../Classes";

export const AuthSignOutButton = () => {

  const user = useContext(AuthContext)
  const history = useHistory()

  const handleConfirm = () => {
    firebaseAuthAPI.signOutAuth(history)
  }

  return (

     user ?
        <div style={{position: 'absolute', top: 0, right: 0}}>
          <ContactButton/>
          <Popconfirm
             title={lang.ASK_SIGN_OUT}
             onConfirm={handleConfirm}
             okText={lang.YES}
             cancelText={lang.ABORT}
          >
            <Button
               type={'text'}
               style={{margin: '0.5rem', color: 'white'}}
               onClick={
                 () => {
                 }
               }>{lang.SIGN_OUT}</Button>

          </Popconfirm>
        </div>
        : null

  )
}
export const AuthWithGoogleButton = () => {

  return (
     <Button
        disabled
        className={'Auth-sign-in-google'}
        type={'ghost'}

        // onClick={() => FirebaseAuth.googleSignInPopup(dispatch)}
     >
       <GoogleOutlined/> {lang.SIGN_IN_WITH_GOOGLE}
     </Button>

  )

}
export const AuthSignUpButton = () => {

  const history = useHistory()

  return (
     <Button
        style={{width: '100%'}}
        actiontype={'navigate'}
        onClick={() =>
           history.push('/sign-up')
        }
     >{lang.SIGN_UP}</Button>

  )
}

export const InputMaxSlots = ({initialValue}) => {

  return (

     <Form.Item
        name={'slots'}
        label={lang.AVAILABLE_SLOTS}
        initialValue={initialValue ? initialValue : CLASSES_SLOTS_ARR ? CLASSES_SLOTS_ARR[CLASSES_SLOTS_ARR.length - 1] : null}
        style={{width: '100%'}}
     >
       <Select>
         {
           CLASSES_SLOTS_ARR.map((num) => (
              <Select.Option key={`slots_${num}`} value={num}>{num}</Select.Option>
           ))
         }
       </Select>
     </Form.Item>
  )
}

export const SignInUpScreenLogo = () => {
  return (<img className={'Auth-logo'} src={base_logo} alt={'logo'}
               style={{filter: 'invert(25%)', marginBottom: '3rem'}}/>)
}
