import React from "react";
import {AuthSignIn} from "../components/Firebase/Auth/SignIn/AuthSignIn";


const PageAuth = () => {

  return (
     <React.Fragment>
       <AuthSignIn/>
     </React.Fragment>
  )

}
export default PageAuth;
