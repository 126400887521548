import React, {useEffect, useState} from "react";
import {Button, Divider} from "antd";
import {displayHttpResponseMessage, getTimeRangeLongTitleCased, getTimeRangeString} from "../../../util";
import {lang} from "../../../config/config";
import {IC_CALENDER_COMPACT, IC_CLOCK_COMPACT, IC_CONTACTS_COMPACT} from "../../Icons/Icons";
import {useDispatch, useSelector} from "react-redux";
import {listOfClasses, removeBookingReserved} from "../../../features/firebase/firestore/firestoreClassesSlice";
import {userData} from "../../../features/firebase/firestore/firestoreCurrentUserData";
import {UsePopConfirm} from "../../SharedComponents/Input";
import {DeleteFilled} from "@ant-design/icons";

const ProfileReservedClasses = () => {

  const user = useSelector(userData)
  const classes = useSelector(listOfClasses)
  const [reservedClasses, setReservedClasses] = useState([])

  useEffect(() => {
    const arr = []
    classes.forEach((classItem) => {
      if (classItem.reservedUsers?.find(x => x.uid === user.uid)) {
        arr.push(classItem)
      }
    })
    // console.log('RESERVEDCLASSES:::', reservedClasses)
    setReservedClasses(arr)
  }, [classes, user.uid])

  return reservedClasses?.length !== 0 ?
     <div style={{display: 'flex', flexDirection: 'column', maxHeight: 400, gap: '1rem', overflowY: 'scroll'}}>
       {/*{console.log('classes', classes.bookedUsers?.filter(x => (x).uid === user.uid))}*/}
       {reservedClasses?.map((item) => (
          <ClassItemCompact key={`class-item-compact-reserved-${item.documentId}`} item={item}/>
       ))}
     </div>
     : <i>{lang.NO_RESERVED_CLASSES_USER}</i>
}

const ClassItemCompact = ({item}) => {

  const dispatch = useDispatch()
  const user = useSelector(userData)

  const dated = `${getTimeRangeLongTitleCased(item.timeRange.classStart)}`
  const timeStartEnd = `${getTimeRangeString(item.timeRange.classStart, item.timeRange.classEnd)}`
  const title = `${item.name}`
  const instructor = `${item.instructor.displayName}`

  const onRemoveBookingClick = () => {

    const findUserReservation = item.reservedUsers?.find(x => (x).uid === user.uid)

    dispatch(removeBookingReserved({
      documentRef: item,
      data: {...findUserReservation}
    })).then((response) => {
      displayHttpResponseMessage(response.payload)
    })
  }

  return (
     <div
        key={`class-item-compact-reserve-${item.name}`}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'noWrap',
          alignItems: 'flex-start',
          minHeight: 150,
          gap: '0.3rem',
          margin: 15
        }}>

       <b>{title}</b>

       <div>
         {IC_CALENDER_COMPACT}{dated}
       </div>
       <div>
         {IC_CLOCK_COMPACT}{timeStartEnd}
       </div>
       <div>
         {IC_CONTACTS_COMPACT}{instructor}
       </div>


       <UsePopConfirm
          key={'popconfirm-reserve'}
          type={'danger'}
          icon={<DeleteFilled style={{color: 'red'}}/>}
          onClick={() => onRemoveBookingClick()}
          titleText={lang.ASK_REMOVE_BOOKING_AS_RESERVE}
          components={[
            <Button danger key={`button-remove-reserve-booking-${item.documentId}`}
                    style={{marginTop: 5, marginBottom: 15}}
                    type={'default'}>{lang.RESERVE_BOOKING_CANCEL}</Button>,
          ]}/>

       <Divider/>

     </div>
  )
}

export default ProfileReservedClasses;
