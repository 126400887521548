import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {listOfAllActiveClasses, updateClassAttendance} from "../../features/firebase/firestore/firestoreClassesSlice";
import {Button, Checkbox, Divider, Modal} from "antd";
import {CardForm} from "../SharedComponents/View";
import {UnorderedListOutlined} from "@ant-design/icons";
import {lang} from "../../config/config";
import {checkedListAssignBoolean, displayHttpResponseMessage} from "../../util";
import {useHistory} from "react-router-dom";
import navigation from "../Navigation/Navigation";
import {} from "react-router-dom"

const ClassAttendance = ({editMode, classId, backPath}) => {

  const dispatch = useDispatch()
  const history = useHistory()

  const listClasses = useSelector(listOfAllActiveClasses)
  const findClass = listClasses.find(x => (x).documentId === classId)
  const [listChecked, setListChecked] = useState([])
  const [displayModal, setDisplayModal] = useState(false)

  const onConfirm = () => {


    history.replace(backPath)
    // navigate(to, { replace: true })

    dispatch(updateClassAttendance({
      documentId: classId,
      data: {
        attendance: listChecked,
        isActive: false,
        bookedUsers: []
      }
    })).then((response) => {
      // displayHttpResponseMessage(response.payload)
    })

  };

  const showModal = () => {
    setDisplayModal(!displayModal)
  };

  const setUserChecked = (data) => {
    const newArr = checkedListAssignBoolean(listChecked, data)
    setListChecked(newArr)
  };

  useEffect(() => {
    const listOriginal = findClass?.bookedUsers
    if(!listOriginal){  history.goBack()  }

    setListChecked(listOriginal?.map(x => ({...x, checked: false})))
  }, [])


  return listClasses ? (
     <React.Fragment>
       <CardForm
          settings={{title: lang.ATTENDANCE, icon: <UnorderedListOutlined/>, navigateBack: true}}
          components={[
            <b style={{fontWeight: 300, position: 'relative', top: -15}}>{`Avser ${findClass?.name}`}</b>,
            <BookedUsers editable={editMode} bookedUsers={findClass?.bookedUsers} setUserChecked={setUserChecked}/>,
          ]}
          buttonRow={[
            editMode ? <Button type={'primary'} text={lang.SAVE_ATTENDANCE} onClick={showModal}>{lang.SAVE_ATTENDANCE}</Button> : null,
          ]}
       >
       </CardForm>

       <Modal
          title="Godkänn"
          visible={displayModal}
          onOk={() => {
            showModal()
            onConfirm()
          }}
          onCancel={() => showModal()}
          okText={lang.YES}
          cancelText={lang.ABORT}
       >
         <p>{lang.ASK_CLASS_ATTENDANCE}</p>
       </Modal>

     </React.Fragment>
  ) : 'Något har blivit fel'
}


const BookedUsers = ({editable, bookedUsers, setUserChecked}) => {

  return (
     <div style={{
       display: 'flex',
       flexDirection: 'column',
       borderRadius: 5,
       margin: 5,
       background: 'rgba(255,255,255)',
       padding: 15,
     }}>
       {bookedUsers?.map((user) => (
          <UserAttendanceItem editable={editable} user={user} setUserChecked={setUserChecked}/>
       ))}
     </div>
  )
}

const UserAttendanceItem = ({editable, user, setUserChecked}) => {

  const [checked, setChecked] = useState(false)

  const onCheckedChange = () => {
    setChecked(!checked)
    setUserChecked({
      user: user, checked: !checked
    })
  }

  return (
     <React.Fragment>
       <div style={{
         display: 'flex',
         flexDirection: 'grid',
         gridTemplateColumns: '49% 49%',
         gap: '1rem',
         minHeight: 50,
       }}>
         <div style={{display: 'flex', flexDirection: 'column', width: '75%', flexAlign: 'flex-start', alignItems: 'start'}}>
           <b style={{fontWeight: 400}}>{user.displayName ? user.displayName : 'Namn saknas'}</b>
           <i>{user?.email}</i>
         </div>

         <div style={{
           display: 'flex',
           width: '25%',
           flexAlign: 'flex-end',
           justifyContent: 'center',
           alignItems: 'center'
         }}>
           { editable ? <Checkbox onChange={onCheckedChange}/> : null }
         </div>
       </div>
       <Divider style={{marginTop: 5, marginBottom: 5}}/>
     </React.Fragment>)
}


export default ClassAttendance;
