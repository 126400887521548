import React, {useState} from 'react';
import {Button, Form, Input, Typography} from 'antd';
import {lang} from "../../../config/config";
import {firebaseAuthAPI} from "../../../features/firebase/auth/firebaseAuthAPI";
import {useSelector} from "react-redux";
import {userData} from "../../../features/firebase/firestore/firestoreCurrentUserData";
import {arrayUnion} from "firebase/firestore";


const PhoneValidationForm = () => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)
  const user = useSelector(userData)

  const onFinishFailed = () => {
  }

  const onFinish = () => {
    setIsLoading(true)
    firebaseAuthAPI.updateAuthProfile({
      phoneNumber: form.getFieldValue('phone'),
      searchParams: arrayUnion(form.getFieldValue('phone'))
    }).then(() => {
      setIsLoading(false)
    })
  }

  return (
     <Form
        name={'form_profile_phone'}
        layout={'vertical'}
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
     >
       <Form.Item
          hasFeedback
          name="phone"
          rules={[{
            required: true, message: 'Vänligen ange ett telefonnummer!'
          },
            ({getFieldValue}) => ({
              validator(_, value) {

                const re = /^[0-9]*$/

                if (value === user.phoneNumber) {
                  return Promise.reject(new Error('Angivet telefonnummer är samma som nuvarande'));
                }

                if (!re.test(value)){
                  return Promise.reject(new Error('Endast siffror är tillåtna.'));
                }

                return Promise.resolve();

              },
            }),
          ]}
       >
         <Input addonBefore={
           <Form.Item noStyle>
             <Typography.Text value="46">+46</Typography.Text>
           </Form.Item>
         } style={{width: '100%'}}
                placeholder={lang.PHONE_NUMBER}
         />
       </Form.Item>

       <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end'}}>
         <Button type={"primary"} loading={isLoading} htmlType={'submit'}>{lang.PHONE_NUMBER_CHANGE}</Button>
       </div>
     </Form>
  )
};

export default PhoneValidationForm;
