import {Button, Form, message} from "antd";
import {useHistory} from "react-router-dom";
import {firebaseAuthAPI} from "../../../../features/firebase/auth/firebaseAuthAPI";
import {InputEmail, InputPassword, UsePopConfirm} from "../../../SharedComponents/Input";
import {lang} from "../../../../config/config";
import {ExclamationCircleFilled} from "@ant-design/icons";
import React from "react";

export const AuthEmailPasswordForm = () => {

  const [form] = Form.useForm();
  const history = useHistory()

  const onSignInClick = () => {
    firebaseAuthAPI.signInUser(form.getFieldValue('email'), form.getFieldValue('auth-password')).then((response) => {
      if (response) {
        history.push('/')
      } else {
        message.warn('Vänligen kontrollera så e-post / lösenord stämmer!')
      }
    })
  }

  const onPasswordResetClick = () => {
    firebaseAuthAPI.resetAuthPassword(form.getFieldValue('email'))
  }

  return (
     <Form
        layout={'vertical'}
        form={form}
     >
       <InputEmail placeholder={lang.EMAIL}/>
       <InputPassword name={'auth-password'} placeholder={lang.PASSWORD}/>

       <Button
          className={'Auth-sign-in'}
          type={'primary'}
          onClick={() => onSignInClick()}
       >
         {lang.SIGN_IN}
       </Button>

       <div style={{display: 'flex', width: '100%', height: 50, justifyContent: 'center', alignItems: 'center'}}>
         <UsePopConfirm
            key={'popconfirm'}
            icon={<ExclamationCircleFilled style={{color: 'blueviolet'}}/>}
            onClick={() => onPasswordResetClick()}
            titleText={lang.ASK_RESET_PASSWORD}
            components={[
              <Button
                 key={'popconfirm-button'}
                 className={'Auth-reset-password'}
                 type={'link'}
                 onClick={() => {
                 }}
              >
                {lang.SIGN_FORGOTTEN_PASSWORD}
              </Button>,
            ]}/>
       </div>
     </Form>
  )
}
