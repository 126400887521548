import React from "react";
import {Button, Form, Input, Popconfirm, Select} from "antd";
import {DeleteFilled, ExclamationOutlined} from "@ant-design/icons";
import {lang} from "../../../config/config";
import {getAvailableSlotsByNum} from "../../../util";
import {TextFieldCharCounter} from "./TextFieldCharCounter";

export const InputEmail = () => {

  return (
     <Form.Item
        name={'email'}
        hasFeedback
        rules={[
          {
            required: true,
            type: 'email',
            message: '',
          },
        ]}
     >
       <Input placeholder={lang.EMAIL}/>
     </Form.Item>
  )
}

export const InputPassword = ({name, placeholder}) => {

  return (
     <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: 'Lösenord krävs!',
          },
        ]}
     >
       <Input.Password
          type={'password'}
          allowClear
          visibilityToggle={true}
          placeholder={placeholder}
       />
     </Form.Item>
  )

}

export const InputPhone = ({name, placeholder}) => {
  return (
     <Form.Item
        name={name}
        rules={[
          {
            required: true,
            message: 'Telefonnummer krävs!',
          },
        ]}
     >
       <Input
          type={'tel'}
          placeholder={placeholder}
       />
     </Form.Item>
  )
}

export const FormDeleteButton = ({onClick, label}) => {
  return (
     <Button
        type={'text'}
        icon={<DeleteFilled/>}
        onClick={onClick}
     >
       {label}
     </Button>
  )
}


export const InputGuestCount = ({bookedUsers, maxSlots}) => {

  const availableSlots = getAvailableSlotsByNum(bookedUsers?.length, maxSlots)
  const range = Array.from({length: availableSlots}, (i, v) => v + 1)

  return (

     <Form.Item
        name={'guest_count'}
        initialValue={range ? range[0] : 0}
        style={{width: '100%'}}
     >
       <Select>
         {
           range.map((num) => (
              <Select.Option key={`guests_${num}`} value={num}>{`${num} gäst/er`}</Select.Option>
           ))
         }
       </Select>
     </Form.Item>
  )
}
export const InputName = ({charCount, setCharCount}) => {

  // const [charCount, setCharCount] = useState(0)
  const maxCount = 15

  const getCharCount = (e) => {
    setCharCount(e.target.value.length)
  }

  return (
     <React.Fragment>

       <Form.Item
          name={'name'}
          style={{width: '100%'}}
          rules={[{
            required: true,
            message: 'Ett namn saknas'
          }]}>

         <Input
            allowClear
            type={"text"}
            placeholder={lang.SUPPLY_A_NAME}
            style={{width: '100%'}}
            maxLength={maxCount}
            onChange={(e) => getCharCount(e)}
         />
       </Form.Item>

       <TextFieldCharCounter charCount={charCount} max={maxCount}/>

     </React.Fragment>
  )
}

export const UsePopConfirm = ({type, icon, titleText, onClick, components}) => {

  const handleConfirm = () => {
    onClick()
  }

  return (
     <React.Fragment>
       <Popconfirm
          okType={type ? type : 'primary'}
          icon={icon ? icon : <ExclamationOutlined/>}
          title={titleText}
          onConfirm={handleConfirm}
          okText={lang.YES}
          cancelText={lang.ABORT}
       >
         {components}
       </Popconfirm>
     </React.Fragment>
  )
}


