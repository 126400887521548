import EmailValidationForm from "./Settings/EmailValidationForm";
import {Button, Divider, Popconfirm} from "antd";
import React from "react";
import PasswordValidationForm from "./Settings/PasswordValidationForm";
import PhoneValidationForm from "./Settings/PhoneValidationForm";
import {lang} from "../../config/config";
import {firebaseAuthAPI} from "../../features/firebase/auth/firebaseAuthAPI";

const ProfileSettings = () => {

  const handleConfirm = () => {
    firebaseAuthAPI.deleteAuthUser()
  }

  return (
     <React.Fragment key={'profile-settings'}>
       <EmailValidationForm key={'validation-email'}/>
       <Divider key={'divider-dashed-1'} dashed/>
       <PasswordValidationForm key={'validation-password'}/>
       <Divider key={'divider-dashed-2'} dashed/>
       <PhoneValidationForm key={'validation-phone'}/>
       <Divider key={'divider-3'}/>
       <div style={{display: 'flex', alignSelf: 'flex-end'}}>
         <Popconfirm
            key={'popconfirm'}
            okType={"danger"}
            title={lang.ASK_DELETE_ACCOUNT}
            onConfirm={handleConfirm}
            okText={lang.YES}
            cancelText={lang.ABORT}
         >
           <Button key={'button-delete-account'} danger type={'ghost'}>{lang.DELETE_ACCOUNT}</Button>
         </Popconfirm>
       </div>
     </React.Fragment>
  )
};

export default ProfileSettings;
