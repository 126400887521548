import PageProfile from "../pages/PageProfile";
import React from "react";
import PageClasses from "../pages/PageClasses";
import PageAdmin from "../pages/PageAdmin";
import {lang} from "../config/config";
import PageCreateClass from "../pages/PageCreateClass";
import PageMyClasses from "../pages/PageMyClasses";
import PageEditClass from "../pages/PageEditClass";
import PageClassAttendance from "../pages/PageClassAttendance";
import PageAdminSearch from "../pages/PageAdminSearch";
import PageAdminCreateEvent from "../pages/PageAdminCreateEvent";
import PageAdminEventEdit from "../pages/PageAdminEventEdit";
import PageAdminActiveClasses from "../pages/PageAdminActiveClasses";
import PageAdminActiveEvents from "../pages/PageAdminActiveEvents";
import PageEvents from "../pages/PageEvents";
import PageWelcome from "../pages/PageWelcome";

export const USER_ACCESS = {
  NONE: "NONE",
  USER: "USER",
  INSTRUCTOR: "INSTRUCTOR",
  ADMIN: "ADMIN"
}

export const routes = [
  {
    key: 'root',
    path: '/',
    name: lang.CLASSES,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.USER,
    exact: true,
    Component: <PageClasses/>
  },
  {
    key: 'events',
    path: '/events',
    name: lang.NAV_EVENTS,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.USER,
    exact: true,
    Component: <PageEvents/>
  },
  {
    key: 'profile',
    path: '/profile',
    name: lang.NAV_PROFILE,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.USER,
    exact: true,
    Component: <PageProfile/>
  },
  {
    key: 'create-class',
    path: '/create-class',
    name: lang.CREATE_CLASS,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.INSTRUCTOR,
    exact: true,
    Component: <PageCreateClass/>
  },
  {
    key: 'edit-class-classId',
    path: '/edit-class/:classId',
    name: 'lang.Edit class',
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.INSTRUCTOR,
    exact: true,
    Component: <PageEditClass/>
  },
  {
    key: 'attendance-classId',
    path: '/attendance/:classId',
    name: 'lang.Narvaro',
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.INSTRUCTOR,
    exact: true,
    Component: <PageClassAttendance />
  },
  {
    key: 'admin-attendance-classId',
    path: '/admin/all-classes/attendance/:classId',
    name: 'lang.Narvaro',
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageClassAttendance />
  },
  {
    key: 'my-classes',
    path: '/my-classes',
    name: lang.MY_CLASSES,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.INSTRUCTOR,
    exact: true,
    Component: <PageMyClasses/>
  },
  {
    key: 'admin',
    path: '/admin',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageAdmin/>
  },
  {
    key: 'welcome',
    path: '/welcome',
    name: "Welcome Page",
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    requiredAccess: USER_ACCESS.USER,
    Component: <PageWelcome/>
  },
  {
    key: 'admin-search-users',
    path: '/admin/search-users',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageAdminSearch/>
  },
  {
    key: 'admin-create-event',
    path: '/admin/create-event',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageAdminCreateEvent/>
  },
  {
    key: 'admin-edit-event-eventId',
    path: '/admin/edit-event/:eventId',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageAdminEventEdit/>
  },
  {
    key: 'admin-all-classes',
    path: '/admin/all-classes',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageAdminActiveClasses/>
  },
  {
    key: 'admin-all-classes-edit',
    path: '/admin/all-classes/:classId',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageEditClass/>
  },
  {
    key: 'admin-events',
    path: '/admin/events',
    name: lang.ADMIN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    requiredAccess: USER_ACCESS.ADMIN,
    exact: true,
    Component: <PageAdminActiveEvents/>
  },
]

export function checkAccess(user, requiredAccess) {

  const ROLES = []
  if (!user) ROLES.push(USER_ACCESS.NONE)
  if (user) {
    if (user?.roles) {
      ROLES.push(...user.roles)
    }
  }

  const access = ROLES.find(x => x === requiredAccess)
  return !(access ? access : false)
}
