import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {CardClasses, NoContent} from "../SharedComponents/View";
import {createReactKeyId} from "../../util";
import {lang} from "../../config/config";
import {message, Spin} from "antd";
import {listOfEvents, removeEventDocument} from "../../features/firebase/firestore/firestoreEventsSlice";
import {QuickNavigationButton} from "../SharedComponents/Basic/ActionButton";
import {ConfigRowAdmin} from "../Classes";
import {EventItem} from "../Events";

const AdminActiveEvents = () => {

  const dispatch = useDispatch()
  const events = useSelector(listOfEvents)

  const onRemoveClick = (item) => {
    return dispatch(removeEventDocument({
      documentRef: item
    })).then(() => {
      message.info('Event borttaget')
    })
  }

  return events?.length !== 0 ?
     <div className={'Event-list-container'}>
       {
         events.map((item) => (
            <CardClasses
               key={`${createReactKeyId('card-events')}`}
               configComponents={[
                 <ConfigRowAdmin key={`${createReactKeyId('cl-cfa')}`} onClickDelete={() => onRemoveClick(item)}
                                 pathEdit={`/admin/edit-event`} item={item}/>
               ]}
               components={[
                 <EventItem key={`${createReactKeyId('aae-ci')}`} item={item}/>,
               ]}
               buttonRow={[]}
            />
         ))
       }
     </div>
     : events?.length === 0 ?
        <NoContent {...{
          title: lang.NO_EVENTS_FOUND,
          description: 'Inga aktiva events hittade!',
          components: [
            <QuickNavigationButton path={'/admin/create-event'} text={lang.CREATE_EVENT}/>,
          ]
        }}/>
        : <Spin/>
}


export default AdminActiveEvents;
