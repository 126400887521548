import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  updateProfile
} from "firebase/auth";
import {message} from "antd";
import {firestoreAPI} from "../firestore/firestoreAPI";
import {arrayUnion, doc, getFirestore, updateDoc} from "firebase/firestore";
import {langResponses} from "../../../config/config";

const COLLECTION_USERS = "users"

export const signUpUser = async (history, email, password, data) => {

  try {
    const auth = await createUserWithEmailAndPassword(getAuth(), email, password);

    return await firestoreAPI.createUserData({
      uid: auth.user.uid,
      data: {...data.data}
    })
  }
  catch(error){
    console.log(error);
  }

}

async function signInUser(email, password) {

  return signInWithEmailAndPassword(getAuth(), email, password)
     .then(() => {
       return true
     })
     .catch(() => {
       // const errorCode = error.code;
       // const errorMessage = error.message;
       return false


     });
}

async function updateAuthEmail(email) {

  updateEmail(getAuth().currentUser, email).then(() => {

    updateAuthProfile({email: email, searchParams: arrayUnion(email)})

  }).catch((error) => {
    // console.log(error)
    message.error('E-post kunde inte ändras!')
  })
}


async function updateAuthPassword(password) {

  updatePassword(getAuth().currentUser, password).then(() => {
    message.success('Lösenord har updaterats!')
  }).catch(() => {
    // console.log(error)
    message.error('Lösenord kunde inte uppdateras!')
  });

}


async function updateAuthProfile(data) {

  updateProfile(getAuth().currentUser, {
    data
  }).then(async () => {
       try {
         const db = getFirestore()
         await updateDoc(doc(db, COLLECTION_USERS, getAuth().currentUser.uid), data);
         message.success('Profil har blivit uppdaterad')
       } catch (e) {
         console.log(e)
         return {type: 'REJECTED', message: langResponses.UPDATE_DOC_USER_FAILED}
       }
     })
     .catch(() => {
       message.error('Profil kunde inte uppdateras!')
     });
}


async function resetAuthPassword(email) {

  sendPasswordResetEmail(getAuth(), email)
     .then(() => {
       message.success('E-post för återställning av lösenord har skickats!')
       // return?
     })
     .catch(() => {
       message.error('Kunde inte hitta e-post. Kolla så den är korrekt ifylld!')
       // return?
     });
}

async function deleteAuthUser() {

  deleteUser(getAuth().currentUser).then(() => {

  }).catch(() => {

  });
}


const signOutAuth = (history) => {
  const auth = getAuth()
  auth.signOut().then(() => {
    history.push('/')
  })
}

export const firebaseAuthAPI = {
  signOutAuth,
  signUpUser,
  signInUser,
  updateAuthEmail,
  updateAuthPassword,
  updateAuthProfile,
  resetAuthPassword,
  deleteAuthUser
}





