import {CardForm} from "../../../SharedComponents/View";
import {lang} from "../../../../config/config";
import {UserOutlined} from "@ant-design/icons";
import {Divider} from "antd";
import React from "react";
import {AuthSignUpButton, SignInUpScreenLogo} from "../index";
import {AuthEmailPasswordForm} from "./index";

export const AuthSignIn = () => {

  return (
     <div className={'Auth-container'}>
       <CardForm
          settings={{title: lang.SIGN_IN_TITLED, icon: <UserOutlined/>}}
          components={[
            <SignInUpScreenLogo key={'auth-logo'}/>,
            <AuthEmailPasswordForm key={'auth-info'}/>,
            <Divider key={'divider'} dashed/>,
            <AuthSignUpButton key={'auth-sign-up'}/>,
          ]}
       />
     </div>
  )

}
