import React from "react";
import {getTimeRangeLongTitleCased, getTimeRangeString, titleCase} from "../../util";
import './Calendar.css'

export const CalenderClassItems = ({arr}) => {

  return (
     <div className={'Calendar-item-container'}>
       {arr?.map(x => (
          <React.Fragment key={`calendar-class-month ${(x).month}`}>
            <div className={'Calendar-item-header-month'}><b>{(x).month}</b></div>
            <div className={'Calendar-item-container sub-container'}>

              {x.items.map(c => (
                 <div key={`calendar-class-month-child ${c.documentId}`}
                      className={'Calendar-item-class-container item'}>
                   <b>{titleCase(c.name)}</b>
                   <b>{getTimeRangeLongTitleCased(c.timeRange.classStart)}</b>
                   <b>{getTimeRangeString(c.timeRange.classStart, c.timeRange.classEnd)}</b>
                   <b>{c?.location ? titleCase(c.location) : c.location}</b>
                 </div>
              ))}
            </div>
            {/*<Divider className={'Divider-month'}/>*/}

          </React.Fragment>
       ))
       }
     </div>
  )
}
