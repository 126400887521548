import React, {useContext} from 'react';
import {NavLink} from "react-router-dom";
import {checkAccess, routes} from "../../router/routes";
import {AuthContext} from "../Firebase/Auth/FirebaseAuthContext";
import {routesPublic} from "../../router/routesPublic";

const Navigation = () => {

  const user = useContext(AuthContext)

  return user ?
     <div className={"Navigation-container"}>
       {routes.map(({key, path, navBarDisplay, name, exact, requiredAccess}) => (
          navBarDisplay.visible ?

             !checkAccess(user, requiredAccess) ?
                <NavLink
                   key={key}
                   className={'App-link'}
                   activeClassName='nav-active'
                   exact={exact}
                   to={path}>{name}</NavLink>
                :
                null
             : null
       ))}
     </div>
     :
     <div className={"Navigation-container"}>
       {routesPublic.map(({key, path, navBarDisplay, name, exact}) => (
          navBarDisplay.visible ?
             <NavLink
                key={key}
                className={'App-link'}
                activeClassName='nav-active'
                exact={exact}
                to={path}>{name}</NavLink>
             : null
       ))}
     </div>

};

export default Navigation;
