import {configureStore} from '@reduxjs/toolkit';
import {firestoreClassesSlice} from "../features/firebase/firestore/firestoreClassesSlice";
import {firestoreUsersSlice} from "../features/firebase/firestore/firestoreUsersSlice";
import {firestoreCurrentUserData} from "../features/firebase/firestore/firestoreCurrentUserData";
import {firestoreCalendarSlice} from "../features/firebase/firestore/firestoreCalendarSlice";
import {firestoreEventsSlice} from "../features/firebase/firestore/firestoreEventsSlice";

export const store = configureStore({
  reducer: {
    currentUserData: firestoreCurrentUserData.reducer,
    firestoreClasses: firestoreClassesSlice.reducer,
    firestoreEvents: firestoreEventsSlice.reducer,
    firestoreUsers: firestoreUsersSlice.reducer,
    firestoreCalendar: firestoreCalendarSlice.reducer,
  },
});
