import React, {useState} from "react";
import {Button, Divider, Form} from "antd";
import {CardForm} from "../SharedComponents/View";
import {ScheduleOutlined} from "@ant-design/icons";
import {
  InputClassName,
  InputClassTime,
  InputCost,
  InputDescription,
  InputEventMaxSlots,
  InputLengthMinutes,
  InputLocation,
  InputStartDate
} from "./../Classes/index";
import {createEventData, displayHttpResponseMessage} from "../../util";
import {useDispatch, useSelector} from "react-redux";
import {EVENT_LENGTH_ARR, lang, langResponses} from "../../config/config";
import {userData} from "../../features/firebase/firestore/firestoreCurrentUserData";
import {
  createEventDocument,
  listOfEvents,
  updateEventDocument
} from "../../features/firebase/firestore/firestoreEventsSlice";
import moment from "moment";
import BookGuestsForm from "../SharedComponents/Input/BookGuestsForm";
import {EventBookedUsersEdit} from "./index";
import {UsePopConfirm} from "../SharedComponents/Input";

const EventForm = ({edit, eventId}) => {

  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [editMode] = useState(edit)
  const user = useSelector(userData)
  const listEvents = useSelector(listOfEvents)
  const [isUploading, setIsUploading] = useState(false)
  const findEvent = editMode ? listEvents.find(x => (x).documentId === eventId) : null

  const onFinishFailed = () => {
  }

  const onFinish = () => {
    // console.log(form.getFieldsValue())
    const data = createEventData(
       form.getFieldValue('startDate')._d,
       form.getFieldValue('endTime')._d,
       form.getFieldValue('lengthMinutes'),
       form.getFieldValue('description'),
       form.getFieldValue('slots'),
       user.uid,
       form.getFieldValue('name'),
       form.getFieldValue('cost'),
       form.getFieldValue('location'),

    )

    editMode ? updateEvent(data) : addEvent(data)

  }

  const addEvent = (data) => {
    setIsUploading(true)
    dispatch(createEventDocument(data)).then((response) => {
      setIsUploading(false)
      displayHttpResponseMessage(response.payload).then(() => {
        if (response.payload.type === langResponses.FULFILLED) {
          form.resetFields()
        }
      })
    })
  }

  const updateEvent = (data) => {
    delete data.bookedUsers
    delete data.createdBy
    setIsUploading(true)

    dispatch(updateEventDocument({
      documentId: eventId,
      data: data
    })).then((response) => {
      setIsUploading(false)
      displayHttpResponseMessage(response.payload)
    })
  }

  return (
     <div style={{display: 'flex', flexDirection: 'column', width: '100%', maxWidth: 600}}>
       <Form
          name={'form_create_class'}
          layout={'vertical'}
          form={form}
          scrollToFirstError
          initialValues={
            {
              startDate: editMode ? moment(findEvent?.timeRange.startDate) : moment(new Date()),
              endTime: editMode ? moment(findEvent?.timeRange.startDate) : moment(new Date()),
              name: editMode ? findEvent?.name : null,
              location: editMode ? findEvent?.location : null,
            }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{width: '100%'}}
       >

         <CardForm
            settings={{title: editMode ? lang.EDIT_EVENT : lang.CREATE_EVENT, icon: <ScheduleOutlined/>}}
            components={[
              <InputClassName key={'input-name'} initialValue={editMode ? findEvent?.name : null}/>,
              <InputDescription maxLength={300} key={'input-description'}
                                initialValue={editMode ? findEvent?.description : null}/>,
              <InputLocation key={'input-location'} initialValue={editMode ? findEvent?.location : null} />,
              // <InputInstructor array={listInstructors}
              //                  initialValue={editMode ? findClass.instructor.displayName : null}/>,
              <InputStartDate key={'input-start-date'}
                              initialValue={editMode ? findEvent?.sessionStart : null}/>,
              <InputClassTime key={'input-class-time'}/>,
              <InputLengthMinutes key={'input-length-minutes'} array={EVENT_LENGTH_ARR}
                                  initialValue={editMode ? findEvent?.sessionLength : null}/>,
              <InputEventMaxSlots key={'input-max-slots'}
                                  initialValue={editMode ? findEvent?.slotRange.total : null}/>,
              <InputCost key={'input-cost'} initialValue={editMode ? findEvent?.cost : null}/>,
              <Divider key={'diver-dashed'} dashed/>,
            ]}
            buttonRow={[
              <UsePopConfirm
                 key={'popconfirm'}
                 onClick={() => {
                   form.validateFields()
                      .then(() => {
                        onFinish()
                      })
                      .catch(() => {
                        onFinishFailed()
                      })
                 }}
                 titleText={lang.ASK_CREATE_EVENT}
                 components={[
                   <Button
                      key={'button-event-submit'}
                      type={'primary'}
                      loading={isUploading}>{editMode ? lang.EDIT_EVENT : lang.CREATE_EVENT}</Button>,
                 ]}/>,
            ]}
         />

       </Form>
       {editMode ? <EventBookedUsersEdit eventItem={findEvent}/> : null}
       {editMode && (eventId) ? <BookGuestsForm item={findEvent} itemType={'eventBookings'}/> : null}
     </div>
  )
}

export default EventForm;
