export const SignedInUser = (uid, email, displayName, phoneNumber, docData) => {
  return {
    uid: uid,
    email: email,
    displayName: displayName,
    phoneNumber: phoneNumber,
    ...docData
  }
}
export const ClassNew = (
   category,
   description,
   slotRange,
   timeRange,
   createdBy,
   instructor,
   name,
   location
) => {
  return {
    category: category,
    description: description,
    slotRange: slotRange,
    timeRange: timeRange,
    createdBy: createdBy,
    instructor: instructor,
    name: name,
    searchName: description.toLowerCase(),
    isActive: true,
    bookedUsers: [],
    reservedUsers: [],
    location: location
  }
}

export const EventNew = (
   description,
   slotRange,
   timeRange,
   createdBy,
   name,
   cost,
   location
) => {
  return {
    description: description,
    slotRange: slotRange,
    timeRange: timeRange,
    createdBy: createdBy,
    name: name,
    cost: cost,
    isActive: true,
    bookedUsers: [],
    reservedUsers: [],
    location
  }
}

export const SlotRange = (
   total,
) => {
  return {
    total: total,
  }
}
export const TimeRange = (
   classStart,
   classEnd,
   classLength,
) => {
  return {
    classStart: classStart,
    classEnd: classEnd,
    classLength: classLength
  }
}
export const Instructor = (
   uid,
   displayName,
) => {
  return {
    uid: uid,
    displayName: displayName
  }
}
