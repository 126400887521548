import React from "react";
import {AuthSignUpForm} from "../components/Firebase/Auth/SignUp/AuthSignUpForm";


const PageAuthSignUp = () => {

  return (
     <React.Fragment>
       <AuthSignUpForm/>
     </React.Fragment>
  )

}
export default PageAuthSignUp;
