import {titleCase} from "../../../util";

export function addDocumentToArray(array, payload) {
  const arrCopy = array?.slice()
  arrCopy.push(payload)

  sortByClassStart(arrCopy)

  return arrCopy
}

export function modifyDocumentInArray(array, payload) {
  const indexOfDoc = array.findIndex((doc) => (doc).documentId === payload.documentId)
  const arrCopy = array.slice()
  arrCopy.splice(indexOfDoc, 1, payload)

  sortByClassStart(arrCopy)

  return arrCopy
}

export function removeDocumentFromArray(array, payload) {
  const indexOfDoc = array.findIndex((doc) => (doc).documentId === payload.documentId)
  const arrCopy = array.slice()
  arrCopy.splice(indexOfDoc, 1)

  sortByClassStart(arrCopy)

  return arrCopy
}

export function getMillisInterval() {

  const date = new Date()
  const date2 = new Date()
  date2.setTime(date.getTime() + (604800000 * 2)) // 604800000 add one week / 7 days
  date.setTime(date.getTime()) //  - 86400000 86 400 000 removes 1 day

  return {
    dateMinMillis: date.getTime(),
    dateMaxMillis: date2.getTime()
  }
}

export function getCalendarDisplayInterval() {

  const date = new Date()
  const date2 = new Date()
  date.setTime(date.getTime()) //  - 86400000 86 400 000 removes 1 day
  date2.setTime(date.getTime() + (604800000 * 6)) // 604800000 add one week / 7 days

  return {
    dateMinMillis: date.getTime(),
    dateMaxMillis: date2.getTime()
  }
}

export function getCalendarDocRefFromMillis(startDateMillis) {
  const date = new Date(startDateMillis)
  return `${(date.getMonth() + 1).toLocaleString('sv-SE', {minimumIntegerDigits: 2})}${date.getFullYear()}`
}

export function getDateFromMillis(startDateMillis) {
  return `${new Date(startDateMillis).getDate().toLocaleString('sv-SE', {minimumIntegerDigits: 2})}`
}

export function sortByClassStart(array) {
  return array.sort(function (a, b) {
    return a.timeRange.classStart - b.timeRange.classStart;
  });
}

export function organizeCalendar(array) {

  const months = array.map((item) => (new Date(item.timeRange.classStart).getMonth() + 1))
  const uniqueMonths = [...new Set(months)]

  const calenderArr = []

  for (const uniqueMonth of uniqueMonths) {
    // console.log(uniqueMonth)
    const filterDates = array.filter(item => (new Date(item.timeRange.classStart).getMonth() + 1) === uniqueMonth)
    const monthString = new Date()
    monthString.setMonth(uniqueMonth - 1)

    calenderArr.push({
      month: titleCase(monthString.toLocaleString('sv-SE', {month: 'long'})),
      items: filterDates
    })
  }

  return calenderArr
}
