import {lang} from "../config/config";
import PageAuth from "../pages/PageAuth";
import React from "react";
import PageAuthSignUp from "../pages/PageAuthSignUp";
import PagePublicCalendar from "../components/Calendar/CalendarPublic";
import PageWelcome from "../pages/PageWelcome";

export const routesPublic = [
  {
    key: 'p-root',
    path: '/',
    name: lang.CLASSES_SCHEDULE_OVERVIEW,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PagePublicCalendar/>
  },
  {
    key: 'p-login',
    path: '/login',
    name: lang.SIGN_IN,
    navBarDisplay: {
      visible: true,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageAuth/>
  },
  {
    key: 'p-sign-up',
    path: '/sign-up',
    name: lang.SIGN_IN,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageAuthSignUp/>
  },
  {
    key: 'welcome',
    path: '/welcome',
    name: lang.CREATE_CLASS,
    navBarDisplay: {
      visible: false,
      iconOnly: false,
      icon: null,
    },
    exact: true,
    Component: <PageWelcome/>
  },
]
