import SE from "../assets/locales/sv-SE.json";
import SE_HTTP_RESPONSES from "../assets/locales/sv-SE-http-responses.json";
import logo from "../assets/images/bw_logo.png";
import classCategories from './../assets/json/classes-categories.json'
import {createTimeRanges} from "../util";


export const BASE_URL = process.env.REACT_APP_CONFIG_ENVIRONMENT_BASE_URL

export const lang = SE
export const langResponses = SE_HTTP_RESPONSES
export const base_logo = logo

const minClassLength = 30
const minClassInterval = 5
const minEventLength = 30
const minEventInterval = 10

const minEventSlots = 10

export const CLASSES_SLOTS_ARR = Array.from({length: 11}, (v, i) => i + 5);
export const EVENT_SLOTS_ARR = Array.from({length: 40 + 1}, (v, i) => i + minEventSlots);
export const CLASSES_SEQUENCES_ARR = Array.from({length: 6}, (v, i) => i + 1);
export const CLASSES_LENGTH_ARR = Array.from({length: 15}, (v, i) => (i * minClassInterval) + minClassLength);
export const EVENT_LENGTH_ARR = Array.from({length: 28}, (v, i) => (i * minEventInterval) + minEventLength);
export const TIMES_ARR = createTimeRanges(7, 22, 6)
export const CLASSES_CATEGORIES_ARR = classCategories

export const FIREBASE_AUTH_TERMS_OF_SERVICE_URL = "https://example.com/"
export const FIREBASE_AUTH_PRIVACY_POLICY_URL = "https://example.com/"

export const MOCK_DEV_PROD = ["MOCK", "DEV", "PROD"][0]




