import React from "react";
import ClassForm from "../components/Classes/ClassForm";

const PageCreateClass = () => {
  return (
     <React.Fragment>
       <ClassForm classId={''} edit={false}/>
     </React.Fragment>
  )
}
export default PageCreateClass;
