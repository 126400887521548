import React from 'react';
import {CardClasses, NoContent} from "../SharedComponents/View";
import {Spin} from "antd";
import {lang} from "../../config/config";
import {createReactKeyId} from "../../util";
import {useSelector} from "react-redux";
import {listOfEvents} from "../../features/firebase/firestore/firestoreEventsSlice";
import './Events.css'
import {EventBookingActionsRow, EventItem} from "./index";

const EventsList = () => {

  const events = useSelector(listOfEvents)

  return events?.length !== 0 ? (
     <div className={'Event-list-container'}>
       {
         events.map((item) => (
            <CardClasses
               key={`${item.documentId}`}
               configComponents={[]}
               components={[
                 <EventItem key={`${createReactKeyId('el-ci')}`} item={item}/>,
               ]}
               buttonRow={[
                 <EventBookingActionsRow key={`${createReactKeyId('el-ebar')}`} item={item}/>,
               ]}
            />
         ))
       }
     </div>
  ) : events?.length === 0 ?
     <NoContent {...{
       title: lang.NO_EVENTS_FOUND,
       description: lang.RETURN_LATER_DESCRIPTION,
       components: []
     }}/>
     : <Spin/>
}

export default EventsList;
