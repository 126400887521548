import React from "react";
import EventForm from "../components/Events/EventForm";

const PageAdminCreateEvent = () => {

  return (
     <React.Fragment>
       <EventForm eventId={''} edit={false}/>
     </React.Fragment>
  )

}
export default PageAdminCreateEvent;
