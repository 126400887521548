import React from "react";
import ClassAttendance from "../components/Classes/ClassAttendance";
import {useLocation, useParams} from "react-router-dom";


const PageClassAttendance = () => {

  const params = useParams()
  const location = useLocation();

  return (
     <React.Fragment>
       <ClassAttendance
          editMode={location.state ? location.state.edit : false}
          classId={params.classId}
          backPath={location.state ? location.state.backPath : '/'}
       />
     </React.Fragment>
  )

}
export default PageClassAttendance;
