import {Form, Input, Typography} from "antd";
import {lang} from "../../../../config/config";
import React from "react";

export const SignUpEmailInput = () => {
  return (
     <Form.Item
        name="email"
        hasFeedback
        rules={[
          {
            required: true,
            type: 'email',
            message: '',
          },
        ]}
     >
       <Input placeholder={lang.EMAIL}/>
     </Form.Item>
  )
}

export const SignUpConfirmPasswordInput = () => {
  return (
     <Form.Item
        name="confirm_password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: lang.PASSWORD_CONFIRM,
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Lösenorden stämmer inte överrens'));
            },
          }),
        ]}
     >
       <Input.Password placeholder={lang.PASSWORD_CONFIRM}/>
     </Form.Item>
  )
}

export const SignUpPasswordInput = () => {
  return (
     <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Vänligen ange ett lösenord!',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || value.length >= 6) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Minst 6 bokstäver och/eller siffror krävs'));
            },
          }),
        ]}
        hasFeedback
     >
       <Input.Password minLength={6} placeholder={lang.PASSWORD}/>
     </Form.Item>
  )
}

export const SignUpFirstNameInput = () => {

  return (
     <Form.Item
        name="firstname"
        rules={[
          {
            required: true,
            message: 'Vänligen ange ett förnamn!',
          },
          ({
            validator(_, value) {
              if (!value || value.length >= 2) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Minst 2 bokstäver krävs'));
            },
          }),
        ]}
        hasFeedback
     >
       <Input type={'string'} minLength={2} placeholder={lang.FIRSTNAME}/>
     </Form.Item>
  )

}

export const SignUpLastNameInput = () => {
  return (
     <Form.Item
        name="lastname"
        rules={[
          {
            required: true,
            message: 'Vänligen ange ett efternamn!',
          },
          ({
            validator(_, value) {
              if (!value || value.length >= 2) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Minst 2 bokstäver krävs'));
            },
          }),
        ]}
        hasFeedback
     >
       <Input minLength={2} placeholder={lang.LASTNAME}/>
     </Form.Item>
  )
}

export const SignUpPhoneInput = () => {
  return (
     <Form.Item
        hasFeedback
        name="phone"
        rules={[{
          required: true, message: 'Vänligen ange ett telefonnummer!'
        }]}
     >
       <Input addonBefore={
         <Form.Item noStyle>
           <Typography.Text value="46">+46</Typography.Text>
         </Form.Item>
       } style={{width: '100%'}} placeholder={lang.PHONE_NUMBER}/>
     </Form.Item>
  )
}
